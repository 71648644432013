import { createContext, useContext } from "react";

export type ContextType = {
    handleCloseDrawer: (path?: string) => void;
    handleMountingDrawer: () => void;
    mounted: boolean;
}

export const context = createContext<ContextType>(null!);

export const { Consumer, Provider } = context;

export const useSFD = () => useContext(context);