import React from "react";
import { Avatar, Badge, Box, IconButton, Tooltip } from "@mui/material";
import { Icon } from "@iconify/react";

const BusinessLogo = ({
	active = false,
	size = 56,
	src,
	caption,
}: {
	active?: boolean;
	size?: number;
	src?: string;
	caption?: string;
}) => (
	<Avatar
		sizes="64px, 128px"
		alt={caption}
		src={src}
		imgProps={{ crossOrigin: "use-credentials" }}
		sx={{
			width: size,
			height: size,
			...(active && {
				border: (theme) => `3px solid ${theme.palette.background.default}`,
				boxShadow: (theme) => `0 0 0 1.2px ${theme.palette.primary.main}`,
			}),
		}}
	>
		{caption}
	</Avatar>
);

export default BusinessLogo;
