import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import { CardActions, CardContent } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import useApi from "hooks/useApi";
import { ReactNode, useCallback } from "react";
import { BusinessTemplateConfig } from "types/business";

export default function TemplateConfigForm({
	children,
	defaultValue,
}: {
	children: ReactNode;
	defaultValue: BusinessTemplateConfig;
}) {
	const api = useApi();
	const handleSubmit = useCallback(
		async (data: BusinessTemplateConfig, { setSubmitting }: FormikHelpers<BusinessTemplateConfig>) => {
			try {
				const response = await api("/business/settings/template/config", {
					body: JSON.stringify({
                        background_color: data.background_color,
                        primary_color: data.primary_color,
                        template_id: data.template_id,
                        ip_id: data.ip_id
                    }),
					method: "put",
				});
				if (response.status === 200) {
					//
				}
			} finally {
				setSubmitting(false);
			}
		},
		[api]
	);

	return (
		<Formik initialValues={defaultValue} onSubmit={handleSubmit}>
			<Form>
				<CardContent>{children}</CardContent>
				<CardActions>
					<LoadingButton
						type="submit"
						variant="contained"
						startIcon={<Icon icon="radix-icons:update" />}
					>
						بروزرسانی
					</LoadingButton>
				</CardActions>
			</Form>
		</Formik>
	);
}
