import { Icon } from "@iconify/react";
import {
	Button,
	Card,
	CardContent,
	Chip,
	FormControl,
	FormGroup,
	Tooltip,
	Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import { nanoid } from "nanoid";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setCredentials } from "reducers/account";
import { useAppDispatch, useAppSelector } from "redux/store";
import VerificationCode from "../../../../components/Auth/VerificationCode";
import useApi from "../../../../hooks/useApi";

interface Values {
	code: string;
	mobile: string;
}

const EnterOTP = ({ signal }: { signal: AbortController["signal"] }) => {
	const api = useApi(signal);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const inputsID = useRef<string>(nanoid());
	const [counter, setCounter] = useState<number>(60);
	const { mobile } = useAppSelector((state) => state.auth);

	const handleSubmit = useCallback(async (data: Values) => {
		try {
			const response = await api("/auth/verify", {
				body: JSON.stringify(data),
				method: "POST",
			});
			const { user, step, tokens } = response.data;
			dispatch(
				setCredentials({
					provider: "sms",
					mobile: user.mobile,
					profile: user.profile,
					tokens,
				})
			);
			if (step === "COMPLETE_PROFILE") {
				return navigate("/auth/complete", { replace: true });
			}
			return navigate("/");
		} catch (e) {
			console.error(e);
		}
	}, []);

	useEffect(() => {
		const timer =
			counter > 0 && setInterval(() => setCounter(counter - 1), 1000);

		return () => {
			if (timer) {
				return clearInterval(timer);
			}
		};
	}, [counter]);

	return (
		<Formik
			initialValues={
				{
					code: "",
					mobile,
				} as Values
			}
			onSubmit={handleSubmit}
		>
			{({ setFieldValue, values, submitForm }) => (
				<Form>
					<FormGroup>
						<VerificationCode
							length={4}
							value={values.code}
							onFillOut={(code) => submitForm()}
							onChange={(code) => setFieldValue("code", code)}
							id={inputsID.current}
						/>
						<FormControl sx={{ mt: 3 }}>
							<Button
								variant="text"
								onClick={() =>
									api("/auth/login", {
										body: JSON.stringify({ mobile }),
										method: "POST",
									}).then(() => {
										setCounter(60);
										inputsID.current = nanoid();
									})
								}
								sx={{ width: "fit-content", mx: "auto" }}
								disabled={counter > 0}
								color={counter > 0 ? "inherit" : "primary"}
							>
								{counter > 0
									? `${counter} ثانیه تا دریافت مجدد کد`
									: "دریافت کد"}
							</Button>
						</FormControl>
					</FormGroup>
				</Form>
			)}
		</Formik>
	);
};

const VerificationPage = () => {
	const controller = useMemo(() => new AbortController(), []);
	const navigate = useNavigate();
	const { mobile, user_summary } = useAppSelector((state) => state.auth);

	useEffect(() => {
		if (!mobile) {
			return navigate("/auth/login");
		}
		return () => controller.abort();
	}, []);

	return (
		<Card variant="outlined">
			<CardContent>
				{user_summary ? (
					<Typography fontSize={"14px"} fontWeight={700} component="span">
						<strong>
							{user_summary.first_name} {user_summary.last_name}
						</strong>
						, خوش آمدید
					</Typography>
				) : (
					<Typography fontSize={"14px"} fontWeight={400} component="span">
						از پیوستن شما به خانواده ماتریکس خرسندیم
					</Typography>
				)}
				<Typography
					fontSize={"14px"}
					fontWeight={700}
					display="flex"
					sx={{
						alignItems: "center",
						justifyContent: "flex-start",
						flexWrap: "wrap",
						gap: 1,
						mb: 2,
					}}
					component="div"
				>
					کد تایید به شماره{" "}
					<Tooltip title={"تغییر شماره"} placement={"top"} arrow>
						<Chip
							label={mobile}
							icon={
								<Icon
									fontSize={"1.5em"}
									icon={"material-symbols:change-circle-rounded"}
								/>
							}
							onClick={() => navigate("/auth/login")}
							sx={{
								fontFamily: "sans-serif",
							}}
						/>
					</Tooltip>{" "}
					ارسال خواهد شد
				</Typography>
				<EnterOTP signal={controller.signal} />
			</CardContent>
		</Card>
	);
};

export default memo(VerificationPage);
