import { Icon } from "@iconify/react";
import { Avatar, Box, styled } from "@mui/material";
import { useFormikContext } from "formik";
import { useEffect, useRef, useState } from "react";

const SIZE = 90;

const Label = styled(Box)(({ theme }) => ({
	position: "relative",
	overflow: "hidden",
	cursor: "pointer",
	borderRadius: "100%",
	width: "fit-content",
	marginInline: "auto",
	[`& .choose-icon`]: {
		position: "absolute",
		inset: 0,
		background: "rgba(0,0,0,.192)",
		display: "grid",
		placeItems: "center",
		fontSize: "2em",
		opacity: 0,
		transform: "scale(0)",
		transition: theme.transitions.create("all", {
			duration: "150ms",
			easing: "cubic-bezier(.2, .1, .5, .2)",
		}),
		borderRadius: "100%",
	},
	[`&:hover .choose-icon`]: {
		opacity: 1,
		transform: "scale(1)",
	},
}));

export default function ChooseAvatarInput() {
	const [src, setSource] = useState<undefined | string>(undefined);

	const { setFieldValue, values } = useFormikContext<any>();

	useEffect(() => {
		if (values.avatar instanceof File) {
			setSource(URL.createObjectURL(values.avatar));
		}
		if (values?.avatar?.filename) {
			setSource(values.avatar.url);
		}
	}, [values.avatar]);

	return (
		<Label component="label">
			<Avatar
				imgProps={{ crossOrigin: "use-credentials" }}
				sx={{ width: SIZE, height: SIZE }}
				src={src}
				alt="Profile Avatar"
			/>
			<div className="choose-icon">
				<Icon icon="material-symbols:photo-size-select-small-sharp" />
			</div>
			<input
				type="file"
				hidden
				name="avatar"
				onChange={(event) => {
					if (event.target.files?.length === 1) {
						setFieldValue("avatar", event.target.files.item(0)!);
					}
				}}
			/>
		</Label>
	);
}
