import { Icon } from "@iconify/react";
import ContainerIconButton from "components/Buttons/ContainerIconButton";
import { NavLink, useParams } from "react-router-dom";

export default function AddBusinessMenuItemButton() {

    const { menu_id } = useParams();

	return (
		<NavLink to={`/dashboard/menus/${menu_id}/items/add`}>
			<ContainerIconButton>
				<Icon icon="material-symbols:add" />
			</ContainerIconButton>
		</NavLink>
	);
}
