import "./globals.css";
import { SnackbarProvider } from "notistack";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "redux/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import MuiProvider from "./theme/mui";
import ThemeProvider from "./theme/Provider";
// Toast
import { Grow } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<Provider store={store}>
		<PersistGate persistor={persistor}>
			<MuiProvider>
				<ThemeProvider>
					<SnackbarProvider
						TransitionComponent={Grow}
						anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
						dense={true}
						autoHideDuration={800}
					>
						<App />
					</SnackbarProvider>
				</ThemeProvider>
			</MuiProvider>
		</PersistGate>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.info);
