import { Icon } from "@iconify/react";
import { Box, Button, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";

export default function NotfoundPage() {
    return (
        <Box sx={{
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            display: "grid",
            placeItems: "center",
            placeContent: "center",
            textAlign: "center"
        }}>
            <Typography component={"strong"} fontWeight={"bold"} fontSize={"5em"} color="#222">
                ۴۰۴
            </Typography>
            <Typography component={"span"} fontSize=".9em" fontWeight="light" color="GrayText">
                صفحه درخواست شده پیدا نشد
            </Typography>
            <NavLink to={"/dashboard"} replace>
                <Button startIcon={<Icon icon="material-symbols:home-app-logo" />} sx={{ mt: 2 }} variant="outlined" color="inherit">برو به صفحه اصلی</Button>
            </NavLink>
        </Box>
    )
}