import { Icon } from "@iconify/react";
import {
	Avatar,
	Button,
	ButtonGroup,
	Card,
	CardActions,
	CardContent,
	IconButton,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Tooltip,
	Typography,
} from "@mui/material";

// export type CommentCardProps = null;

export default function CommentCard() {
	return (
		<ListItem>
			<ListItemAvatar>
				<Avatar
					sx={{ width: 40, height: 40 }}
					variant="circular"
					alt="Commenter"
				/>
			</ListItemAvatar>
			<ListItemText
				primary={
					<>
						<small>نویسنده:</small> <strong>امیر محمد ریوند</strong>
					</>
				}
				primaryTypographyProps={{
					fontSize: ".8em",
					fontWeight: "light",
					color: "GrayText",
				}}
				secondary={
					<Card color="default">
						<CardContent>
							<Typography
								component={"div"}
								fontSize=".9em"
								color="#222"
								textAlign="justify"
							>
								لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
								استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله
								در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد
								نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد
							</Typography>
						</CardContent>
						<CardActions>
							<ButtonGroup>
								<Tooltip title="تایید بازخورد">
									<IconButton size="small" color="success">
										<Icon icon="iconoir:security-pass" />
									</IconButton>
								</Tooltip>
								<Tooltip title="ارسال پاسخ">
									<IconButton color="info" size="small">
										<Icon icon="material-symbols:reply" />
									</IconButton>
								</Tooltip>
								<Tooltip title="حذف کردن">
									<IconButton color="error" size="small">
										<Icon icon="material-symbols:delete-forever-outline-sharp" />
									</IconButton>
								</Tooltip>
							</ButtonGroup>
						</CardActions>
					</Card>
				}
			/>
		</ListItem>
	);
}
