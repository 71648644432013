import { Alert, Collapse, Typography } from "@mui/material";

export default function ZeroMenuItems() {
	return (
		<Collapse in>
			<Alert severity="warning">
				<Typography fontSize="1em" component="span">
					این منو دارای هیچ آیتمی نمی‌باشد!
				</Typography>
			</Alert>
		</Collapse>
	);
}
