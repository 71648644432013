import {
	Stack
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, {
	createRef,
	forwardRef,
	useEffect,
	useRef,
	useState,
} from "react";

const NumInput = styled("input")(({ theme }) => ({
	display: "grid",
	placeContent: "center",
	aspectRatio: "1/1",
	width: 32,
	borderRadius: theme.spacing(.5),
	color: theme.palette.primary.main,
	textAlign: "center",
	border: `1px solid ${theme.palette.grey[500]}`,
	outline: "none !important",
	fontSize: "1em",
	"&:focus": {
		borderColor: theme.palette.grey[700],
		boxShadow: "0 3px 6px rgba(0,0,0,.092)",
	},
}));

const OTPInput = forwardRef<
	HTMLInputElement,
	React.InputHTMLAttributes<HTMLInputElement>
>(function OTP(props, ref) {
	const [value, setValue] = useState("");

	return (
		<NumInput
			ref={ref}
			required
			type={"tel"}
			value={value}
			onChange={(e) => setValue(e.target.value)}
			size={1}
			inputMode="tel"
			maxLength={1}
			name="otp"
		/>
	);
});

interface Props {
	length: number;
	onChange: (value: string) => void;
	onFillOut?: (code?: string) => void;
	value: string;
	id: string;
}

const VerificationCode = ({
	length,
	onChange,
	value,
	onFillOut,
	id,
}: Props) => {
	const Length = new Array(length).fill("");
	const refs = useRef<null | React.RefObject<HTMLInputElement>[]>(null);

	useEffect(() => {
		if (refs.current === null) {
			refs.current = Length.map(() => createRef<HTMLInputElement>());
		}
	}, []);

	useEffect(() => {
		if (refs.current !== null) {
			refs.current?.forEach((ref, i) => {
				if (ref.current) {
					ref.current.onfocus = () => {
						ref.current?.select();
					};
					ref.current.onkeyup = (event) => {
						if (event.keyCode === 8 || event.keyCode === 37) {
							if (refs.current && refs.current[i - 1]?.current) {
								refs.current[i - 1].current?.focus();
							}
							return;
						}
						if (event.code === "Enter") {
							if (refs.current && refs.current[i + 1].current) {
								refs.current[i + 1].current?.focus;
							}
						}
						if(event.code === "Tab") { return ; }
						const code = refs.current
							?.map((ref) => ref.current?.value)
							.join("") as string;
						onChange(code);
						if (refs.current && refs.current[i + 1]) {
							refs.current[i + 1]?.current?.focus();
						}
					};
				}
			});
		}
	}, [refs.current]);

	useEffect(() => {
		refs.current?.forEach((ref) => {
			if (ref.current) {
				ref.current.value = "";
			}
		});
	}, [id]);

	useEffect(() => {
		if (value.length === length && onFillOut) {
			onFillOut(value);
		}
	}, [value]);

	return (
		<Stack
			direction={"row-reverse"}
			alignItems={"center"}
			justifyContent={"center"}
			gap={2}
			flexWrap={"wrap"}
			width={"fit-content"}
			mx="auto"
		>
			{refs.current?.map((ref, i) => (
				<OTPInput ref={ref} key={i} />
			))}
		</Stack>
	);
};

export default VerificationCode;
