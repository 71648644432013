import React, { memo, useCallback, useEffect, useMemo } from "react";
import {
	Card,
	CardContent,
	FormControl,
	FormGroup,
	Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { LoadingButton } from "@mui/lab";
import useApi from "hooks/useApi";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/store";
import { setCredentials } from "reducers/account";

interface Values {
	first_name: string;
	last_name: string;
	email: string;
	nid: string;
}

const ProfileForm = ({ signal }: { signal: AbortController["signal"] }) => {
	const api = useApi(signal);
	const navigate = useNavigate();
	const account = useAppSelector((state) => state.account);
	const dispatch = useAppDispatch();

	const handleSubmit = useCallback(
		async (data: Values, { setSubmitting }: any) => {
			try {
				const response = await api("/auth/register", {
					body: JSON.stringify(data),
					method: "POST",
				});
				if (response.status === 201) {
					if (response.data.credentials) {
						dispatch(setCredentials(response.data.credentials));
					}
					return navigate("/", { replace: true });
				}
				if (response.status === 422) {
					window.alert("problem");
				}
			} catch (e) {
				console.error(e);
			} finally {
				setSubmitting(false);
			}
		},
		[dispatch]
	);

	useEffect(() => {
		if (account?.profile) {
			return navigate("/");
		}
		if (!account?.tokens?.accessToken) {
			return navigate("/auth/login");
		}
	}, []);

	return (
		<Formik
			initialValues={
				{
					first_name: "",
					last_name: "",
					email: "",
					nid: "",
				} as Values
			}
			onSubmit={handleSubmit}
		>
			{({ isSubmitting }) => (
				<Form>
					<FormGroup sx={{ mt: 1, gap: (theme) => theme.spacing(2) }}>
						<FormControl>
							<Typography
								display={"block"}
								width={"100%"}
								fontSize={"14px"}
								fontWeight={700}
								component={"span"}
							>
								تکمیل ثبت نام
							</Typography>
						</FormControl>
						<FormControl>
							<Field
								component={TextField}
								name={"first_name"}
								label={"نام"}
								shrink
							/>
						</FormControl>
						<FormControl>
							<Field
								component={TextField}
								name={"last_name"}
								label={"نام خانوادگی"}
							/>
						</FormControl>
						<FormControl>
							<Field
								component={TextField}
								type={"email"}
								dir={"ltr"}
								name={"email"}
								label={"ایمیل"}
							/>
						</FormControl>
						<FormControl>
							<Field
								component={TextField}
								inputProps={{
									inputMode: "numeric",
									autoComplete: "off",
								}}
								dir={"ltr"}
								name={"nid"}
								label={"شماره ملی"}
								min={10}
								max={10}
							/>
						</FormControl>
						<FormControl>
							<LoadingButton
								type="submit"
								loading={isSubmitting}
								variant={"contained"}
							>
								ثبت
							</LoadingButton>
						</FormControl>
					</FormGroup>
				</Form>
			)}
		</Formik>
	);
};

const CompleteProfile = () => {
	const controller = useMemo(() => new AbortController(), []);

	useEffect(() => {
		return () => controller.abort();
	}, []);

	return (
		<Card variant="outlined">
			<CardContent>
				<ProfileForm signal={controller.signal} />
			</CardContent>
		</Card>
	);
};

export default memo(CompleteProfile);
