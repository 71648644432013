import { ReactNode, useCallback, useState, useTransition } from "react";
import { Provider } from "./context";
import { useNavigate } from "react-router-dom";

export default function SwipeableFormDrawerProvider(props: {
	children: ReactNode;
}) {
	const [mounted, setMounted] = useState(false);
	const [isPending, startTransition] = useTransition();

	const navigate = useNavigate();

	const handleMountingDrawer = useCallback(() => {
		if (!mounted) {
			startTransition(() => {
				setMounted(true);
			});
		}
	}, []);

	const handleCloseDrawer = useCallback((path?: string) => {
		if (mounted) {
			startTransition(() => {
				setMounted(false);
				const timeout = setTimeout(() => {
					if(path) {
						navigate(path);
					} else {
						navigate(-1);
					}
					clearTimeout(timeout);
				}, 300);
			});
		}
	}, [mounted]);

	const pass_props = {
		handleMountingDrawer,
		handleCloseDrawer,
        isPending,
        mounted
	};

	return <Provider value={pass_props}>{props.children}</Provider>;
}
