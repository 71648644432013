import { useCallback } from "react";

export type FormDataType = {
    [key: string]: string|number|File|Array<unknown>|object;
}

const useFormHelper = () => {

    const serialize = useCallback((data: any) => {
        const fd = new FormData();
        Object.entries(data as FormDataType).forEach(([key, value]) => {
            if(value instanceof File) {
                fd.append(key, value, value.name);
            } else if(typeof value === "string") {
                fd.append(key, value);
            } else if (typeof value === "number") {
                fd.append(key, String(value));
            } else if (Array.isArray(value)) {
                value.forEach((v, index) => {
                    fd.append(`${key}[${index}]`, v);
                });
            }
        });
        return fd;
    }, []);

    return { serialize }
};

export default useFormHelper;