import { BusinessMenuItem } from "types/BusinessMenuItem";
import api from "./index";

const menuItemsApi = api.injectEndpoints({
	endpoints: (build) => ({
		getBusinessMenusItems: build.query<BusinessMenuItem[], { menu_id: number }>(
			{
				query: ({ menu_id }) => `/business/menus/${menu_id}/items`,
				keepUnusedDataFor: 0,
				providesTags: ["MENU_ITEMS"],
			}
		),
		getBusinessMenuItem: build.query<
			BusinessMenuItem,
			{ menu_id: number; id: number }
		>({
			query: ({ menu_id, id }) => `/business/menus/${menu_id}/items/${id}`,
			providesTags: ["MENU_ITEMS"],
			keepUnusedDataFor: 0,
		}),
		addBusinessMenuItem: build.mutation<
			{ entry?: BusinessMenuItem },
			{ menu_id: number; body: FormData }
		>({
			query: ({ menu_id, body }) => ({
				url: `/business/menus/${menu_id}/items`,
				body,
				method: "POST",
			}),
			invalidatesTags: (result) => {
				if (result?.entry) {
					return [
						"MENU_ITEMS",
						"MENUS",
						{ type: "MENUS", id: result.entry.menu_id },
					];
				}

				return ["MENU_ITEMS", "MENUS"];
			},
		}),
		updateBusinessMenuitem: build.mutation<
			BusinessMenuItem,
			{ id: any; menu_id: any; body: unknown }
		>({
			query: ({ id, menu_id, body }) => ({
				url: `/business/menus/${menu_id}/items/${id}`,
				body,
				method: "PUT",
			}),
			invalidatesTags: ["MENU_ITEMS", "MENUS"],
		}),
		deleteBusinessMenuItem: build.mutation<unknown, { menu_id: any; id: any; }>({
			query: ({ menu_id, id }) => ({
				url: `/business/menus/${menu_id}/items/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["MENU_ITEMS", "MENUS"],
		}),
		toggleMenuItemVisibility: build.mutation<boolean, { id: number; menu_id: number }>({
			query: ({ id, menu_id }) => ({
				url: "/business/menus/" + menu_id + "/items/" + id,
				method: "PATCH",
			}),
			invalidatesTags: ["MENU_ITEMS", "MENUS"],
		}),
	}),
});

export const {
	useGetBusinessMenuItemQuery,
	useGetBusinessMenusItemsQuery,
	useAddBusinessMenuItemMutation,
	useUpdateBusinessMenuitemMutation,
	useDeleteBusinessMenuItemMutation,
	useToggleMenuItemVisibilityMutation
} = menuItemsApi;

export default menuItemsApi;
