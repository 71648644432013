import { FormControl, Button } from "@mui/material";
import VerificationCode from "components/Auth/VerificationCode";
import { useFormikContext } from "formik";
import useApi from "hooks/useApi";
import { nanoid } from "nanoid";
import { useRef, useState, useEffect } from "react";

const EnterOTP = () => {
	const inputsID = useRef<string>(nanoid());

	const { setFieldValue, values, submitForm } = useFormikContext<any>();
	const api = useApi();

	const [counter, setCounter] = useState<number>(60);

	useEffect(() => {
		const timer =
			counter > 0 && setInterval(() => setCounter(counter - 1), 1000);

		return () => {
			if (timer) {
				return clearInterval(timer);
			}
		};
	}, [counter]);

	return (
		<>
			<VerificationCode
				length={4}
				value={values.otp}
				onFillOut={() => submitForm()}
				onChange={(code) => setFieldValue("otp", code)}
				id={inputsID.current}
			/>
			<FormControl sx={{ mt: 3 }}>
				<Button
					variant="text"
					onClick={() =>
						api("/auth/login", {
							body: JSON.stringify({ mobile: values.mobile }),
							method: "POST",
						}).then(() => {
							setCounter(60);
							inputsID.current = nanoid();
						})
					}
					sx={{ width: "fit-content", mx: "auto" }}
					disabled={counter > 0}
					color={counter > 0 ? "inherit" : "primary"}
				>
					{counter > 0 ? `${counter} ثانیه تا دریافت مجدد کد` : "دریافت کد"}
				</Button>
			</FormControl>
		</>
	);
};

export default EnterOTP;