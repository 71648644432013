import React from 'react';
import { Stack, Typography } from "@mui/material"

type BusinessSummaryItemProps = {
    label: string;
    count: number;
}

const BusinessSummaryItem = ({ label, count }: BusinessSummaryItemProps) => {
    return (
			<Stack alignItems="center" justifyContent={"center"} direction="column">
				<Typography
					component="span"
					fontWeight="bold"
					color="primary.dark"
					fontSize=".9em"
					lineHeight={1}
				>
					{count}
				</Typography>
				<Typography
					component="span"
					fontWeight="light"
					color="primary.dark"
					fontSize=".7em"
					// lineHeight={1}
				>
					{label}
				</Typography>
			</Stack>
		);
}

export default BusinessSummaryItem;