import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import {
	Avatar,
	Box,
	Button,
	Chip,
	Collapse,
	Divider,
	FormControl,
	FormGroup,
	FormHelperText,
	Grow,
	IconButton,
	InputLabel,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	MenuItem,
	Paper,
	Select,
	Tooltip,
	Typography,
} from "@mui/material";
import PageLayout from "components/PageLayout";
import { Field, Form, Formik, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import useApi from "hooks/useApi";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { initialize } from "reducers/workspace";
import { useAppDispatch, useAppSelector } from "redux/store";
import BusinessCategoryType from "types/businesscategory";

function BusinessCategory() {
	const [categories, setCategories] = useState<BusinessCategoryType[] | null>(
		null
	);

	const api = useApi();

	const loadCategories = useCallback(async () => {
		const response = await api("/global/categories");
		if (response.data?.length > 0) {
			setCategories(response.data);
		}
	}, [api]);

	useEffect(() => {
		if (categories === null) {
			loadCategories();
		}
	}, []);

	const { getFieldProps, touched, errors } = useFormikContext<any>();

	return (
		<FormControl>
			<InputLabel id="bc-label">دسته بندی</InputLabel>
			<Select
				labelId="bc-label"
				label="دسته بندی"
				variant="standard"
				{...getFieldProps("category_id")}
			>
				{categories?.map((category: BusinessCategoryType, index: number) => (
					<MenuItem value={category.id} key={`BC-li-${index}`} dense>
						<ListItemText primary={category.name} secondary={category.latin} />
					</MenuItem>
				))}
			</Select>
			<FormHelperText>
				{Boolean(touched.category_id && errors.category_id) && (
					<Typography>{errors.category_id?.toString()}</Typography>
				)}
			</FormHelperText>
		</FormControl>
	);
}

function LogoPickup() {
	const { setFieldValue, values } = useFormikContext<any>();
	return (
		<>
			<Divider textAlign="center">
				{values.business_logo instanceof File ? (
					<Grow in>
						<Tooltip placement="bottom" title="برای حذف تصویر کلیک کنید">
							<Avatar
								variant="circular"
								sx={{ width: 90, height: 90, cursor: "pointer" }}
								src={URL.createObjectURL(values.business_logo)}
								onClick={() => setFieldValue("business_logo", null)}
								alt={values.business_logo?.name}
							/>
						</Tooltip>
					</Grow>
				) : (
					<Tooltip title="انتخاب لوگو" placement="bottom">
						<IconButton component="label">
							<input
								type="file"
								hidden
								multiple={false}
								max={1}
								onChange={(event) => {
									if (event.target.files && event.target.files.item(0)) {
										setFieldValue("business_logo", event.target.files.item(0));
									}
								}}
							/>
							<Icon icon="ph:codesandbox-logo" />
						</IconButton>
					</Tooltip>
				)}
			</Divider>
		</>
	);
}

function BusinessSubmittingForm() {
	const api = useApi();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const account = useAppSelector((state) => state.account);

	return (
		<Formik
			initialValues={{
				business_name: "",
				business_subdomain: "",
				business_custom_domain: "",
				business_address: "",
				business_connector_mobile: account?.mobile || "",
				business_logo: null! as File,
				category_id: "",
			}}
			onSubmit={async (data, { setSubmitting }) => {
				try {
					const fd = new FormData();
					fd.append("business_name", data.business_name);
					fd.append("business_subdomain", data.business_subdomain);
					fd.append("business_custom_domain", data.business_custom_domain);
					fd.append("business_address", data.business_address);
					fd.append(
						"business_connector_mobile",
						data.business_connector_mobile
					);
					fd.append("category_id", data.category_id);
					if (data.business_logo) {
						fd.append(
							"business_logo",
							data.business_logo,
							data.business_logo.name
						);
					}

					const response = await api("/submission", {
						body: fd,
						method: "POST",
					});
					if (response.status === 201) {
						const response = await api("/dashboard");
						dispatch(initialize(response.data));
						return navigate("/");
					}
				} catch (err) {
					console.error(err);
				} finally {
					setSubmitting(false);
				}
			}}
		>
			{({ isSubmitting }) => (
				<Form>
					<FormGroup>
						<BusinessCategory />
						<Field
							name="business_name"
							dir="rtl"
							label="نام مجموعه"
							component={TextField}
							autoComplete={false}
							InputProps={{
								required: true,
								autoComplete: false,
							}}
							variant="standard"
						/>
						<Field
							name="business_subdomain"
							label="زیر دامنه (نام لاتین مجموعه)"
							variant="standard"
							component={TextField}
							InputProps={{
								dir: "ltr",
								required: true,
							}}
							autoComplete={false}
							helperText={
								<List sx={{ listStyle: "circle inside" }}>
									<li>
										این رشته جهت آدرس دهی منوی شما مورد استفاده قرار خواهد گرفت
									</li>
									<li>به انگلیسی و بدون فاصله وارد شود</li>
								</List>
							}
						/>
						<Field
							name="business_custom_domain"
							label="دامنه شخصی"
							component={TextField}
							InputProps={{ dir: "ltr", autoComplete: "off" }}
							type="website"
							helperText={
								"درصورتی که میخواهید دامنه شخصی خود را به منو وصل کنید ، آدرس دامنه را وارد نمایید."
							}
							variant="standard"
						/>
						<Field
							name="business_address"
							label="آدرس مجموعه"
							rows={3}
							multiline
							component={TextField}
							InputProps={{ autoComplete: "off" }}
							variant="standard"
						/>
						<Field
							name="business_connector_mobile"
							label="موبایل رابط مجموعه"
							component={TextField}
							InputProps={{ dir: "ltr", autoComplete: "off" }}
							variant="standard"
							disabled={Boolean(account?.mobile)}
						/>
						<LogoPickup />
						<LoadingButton
							sx={{
								width: "fit-content",
								px: 2,
								ml: "auto",
							}}
							variant="contained"
							type="submit"
							loading={isSubmitting}
							startIcon={<Icon icon="material-symbols:check-box" />}
						>
							ثبت
						</LoadingButton>
					</FormGroup>
				</Form>
			)}
		</Formik>
	);
}

export default function RegisterBusiness() {
	const { businesses } = useAppSelector((state) => state.workspace);
	return (
		<PageLayout
			topAppBar={{
				title: "ثبت نام کسب و کار",
				centeredTitle: true,
				backPath: "/dashboard",
				arrowBack: businesses ? businesses.length > 0 : false,
			}}
		>
			<Paper sx={{ p: 2 }}>
				<BusinessSubmittingForm />
			</Paper>
		</PageLayout>
	);
}
