import { IconButton, iconButtonClasses, styled } from "@mui/material";

const ContainerIconButton = styled(IconButton)(({ theme }) => ({
    borderRadius: theme.spacing(1),
    backgroundColor: "rgba(255,255,255,.092)",
    border: `1px solid rgba(0,0,0,.192)`,
    transition: theme.transitions.create("all", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    "&:hover": {
        backgroundColor: `rgba(255,255,255,.192)`,
    },
    [`&.${iconButtonClasses.sizeMedium}`]: {
        padding: theme.spacing(.5),
    }
}));

export default ContainerIconButton;