import { Icon } from "@iconify/react";
import {
	Box,
	Button,
	Divider,
	Typography
} from "@mui/material";
import { memo, useEffect, useMemo } from "react";
import { NavLink } from "react-router-dom";
import EnterMobile from "./EnterMobile";

export interface Values {
	mobile: string;
	otp: string;
	password: string;
}

const LoginPage = () => {
	const controller = useMemo(() => new AbortController(), []);

	useEffect(() => {
		return () => controller.abort();
	}, []);

	return (
		<>
			<Typography
				component={"span"}
				color="#353535"
				fontSize=".9em"
				fontWeight="bold"
				gutterBottom
				display="block"
			>
				ورود به پنل کاربری
			</Typography>
			<Typography
				component={"span"}
				color="#353535"
				fontSize=".7em"
				display="block"
				sx={{ mb: 2 }}
			>
				ورود و ثبت نام از همینجاس ...
			</Typography>
			<EnterMobile signal={controller.signal} />
			<Divider
				textAlign="center"
				sx={{ my: 2, fontSize: ".7em", color: "GrayText" }}
			>
				قبل از ورود
			</Divider>
			<Box mx="auto" width="fit-content">
				<NavLink to="/submit-business">
					<Button
						variant="outlined"
						color="inherit"
						startIcon={<Icon icon="material-symbols:business-center-rounded" />}
					>
						ثبت نام کسب و کار
					</Button>
				</NavLink>
			</Box>
		</>
	);
};

export default memo(LoginPage);
