import { Icon } from "@iconify/react";
import {
	ListItemButton,
	ListItemButtonProps,
	ListItemIcon,
	ListItemText,
	useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

type Props = {
	divider?: boolean;
	label: string;
	iconName: string;
	onClick?: ListItemButtonProps["onClick"];
	href?: string;
	disabled?: boolean
};

const BusinessAction = ({ divider, label, iconName, onClick, href, disabled }: Props) => {
	const navigate = useNavigate();
	const theme = useTheme();
	if(href) {
		onClick = () => navigate(href);
	}
	return (
		<ListItemButton divider={divider} onClick={onClick} disabled={disabled}>
			<ListItemIcon sx={{ minWidth: (theme) => theme.spacing(4) }}>
				<Icon icon={iconName} color={theme.palette.secondary.light} />
			</ListItemIcon>
			<ListItemText primary={label} primaryTypographyProps={{
				fontSize: ".8em",
				fontWeight: 400
			}} />
			<Icon icon={"ic:round-keyboard-arrow-left"} />
		</ListItemButton>
	);
};

export default BusinessAction;
