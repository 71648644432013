import AppNavigation from "components/AppNavigation";
import BusinessSwitcher from "components/BusinessSwitcher";
import PageLayout from "components/PageLayout";
import BusinessDashboard from "../BusinessDashboard";

const Dashboard = () => {
	return (
		<PageLayout
			bottomAppBar={{
				children: <AppNavigation />,
			}}
		>
			<BusinessSwitcher />
			<BusinessDashboard />
		</PageLayout>
	);
};

export default Dashboard;
