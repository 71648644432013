import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Media } from "types/media";

type AuthProvider = "sms";

export type UserProfile = {
    first_name: string;
    last_name: string;
    nid: string;
    email: string;
    birth_date: Date;
    avatar: Media;
}
export type Account = {
    mobile: string;
    tokens: {
        accessToken: string;
        refreshToken: string;
    },
    profile: UserProfile;
    provider: AuthProvider;
};

const account = createSlice({
    name: "@account",
    initialState: null as Account | null,
    reducers: {
        setCredentials: (state, action: PayloadAction<Account>) => ({ ...action.payload }),
        clearCredentials: () => (null),
        updateProfile: (state, action: PayloadAction<Account["profile"]>) => {
            (state as Account).profile = action.payload;
        }
    }
});

export const {
    setCredentials,
    clearCredentials,
    updateProfile
} = account.actions;

export default account.reducer;