import { Alert, Collapse, Typography } from "@mui/material";

export default function ZeroMenus() {
	return (
		<Collapse in>
			<Alert severity="warning">
				<Typography fontSize="1em" component="span">
					تاکنون برای این کسب و کار منو ثبت نشده است!
				</Typography>
			</Alert>
		</Collapse>
	);
}
