import { Card, CardContent } from "@mui/material";
import AppNavigation from "components/AppNavigation";
import PageLayout from "components/PageLayout";
import UserDetails from "./UserDetails";
import UserSummary from "./UserSummary";
import { Outlet } from "react-router-dom";

export default function UserProfile() {
	return (
		<PageLayout
			topAppBar={{
				title: "پروفایل",
				arrowBack: false,
			}}
			bottomAppBar={{
				children: <AppNavigation />,
			}}
		>
			<Card>
				<CardContent>
					<UserSummary />
					<UserDetails />
				</CardContent>
			</Card>
			<Outlet />
		</PageLayout>
	);
}
