import { ListItem } from "@mui/material";
import StyledCardOne from "components/Cards/StyledCardOne";
import useApi from "hooks/useApi";
import { useCallback, useTransition } from "react";
import { useAppDispatch, useAppSelector } from "redux/store";
import { switchWorkingBusiness, toggleSelectOpen } from "reducers/workspace";
import { useNavigate } from "react-router-dom";

export type BusinessCardProps = {
	title: string;
	subdomain: string;
	id: string | number;
	logoUrl?: string;
};

const BusinessCard = ({ title, subdomain, id, logoUrl }: BusinessCardProps) => {

	const api = useApi();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { select_open } = useAppSelector((state) => state.workspace);

	const [isPending, startTransition] = useTransition();

	const connect = useCallback(async (businessID: string) => {
		try {
			const response = await api(`/business/${businessID}/connect`, {
				method: "POST"
			});

			startTransition(() => {
				dispatch(switchWorkingBusiness(response.data.business));
				if(select_open) {
					dispatch(toggleSelectOpen(false));
				} else {
					navigate("/dashboard");
				}
			});

		} catch (err) {
			window.alert("ارتباط با کسب و کار برقرار نشد!");
		}
	}, [api, dispatch, navigate]);

	return (
		<ListItem>
			<StyledCardOne
				title={title}
				subheader={subdomain}
				onClickAction={() => connect(id as string)}
				loading={isPending}
				thumbnail={logoUrl}
			/>
		</ListItem>
	);
};

export default BusinessCard;
