import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import {
	CardActions,
	CardContent,
	FormGroup
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import useApi from "hooks/useApi";
import { Settings } from ".";

export default function SocialAccountsForm({
	data,
}: {
	data: Settings | null;
}) {
	const api = useApi();

	return (
		<Formik
			initialValues={{
				ig: data?.socialAccounts?.ig,
				foursquare: data?.socialAccounts?.foursquare,
			}}
			onSubmit={async (data, { setSubmitting }) => {
				try {
					const response = await api(`/business/settings/socialAccounts`, {
						method: "PUT",
						body: JSON.stringify(data),
					});
				} finally {
					setSubmitting(false);
				}
			}}
		>
			<Form>
				<CardContent>
					<FormGroup>
						<Field
							component={TextField}
							name="ig"
							label="Instagram"
							dir="ltr"
						/>
						<Field
							component={TextField}
							name="foursquare"
							label="FourSquare"
							dir="ltr"
						/>
					</FormGroup>
				</CardContent>
				<CardActions>
					<LoadingButton
						type="submit"
						variant="contained"
						startIcon={<Icon icon="radix-icons:update" />}
					>
						بروزرسانی
					</LoadingButton>
				</CardActions>
			</Form>
		</Formik>
	);
}
