import { LoadingButton } from "@mui/lab";
import { Alert, Dialog, DialogActions, DialogContent, FormGroup, Typography } from "@mui/material";
import DialogHeader from "components/DialogHeader";
import { useSubmitMenuTitleMutation } from "features/entities/helpers";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { TextField } from "formik-mui";
import { useCallback } from "react";

type Props = {
    handleClose: () => void;
}

export default function AddCustomMenuForm({ handleClose }: Props) {

    const [execute, api] = useSubmitMenuTitleMutation();

    const handleSubmit = useCallback(async (data: any, options: FormikHelpers<any>) => {
        try {
            await execute(data);
            handleClose();
        } catch (err) {
            window.alert("ثبت عنوان منو با خطا مواجه گردید!");
        } finally {
            options.setSubmitting(false);
        }
    }, []);

    return (
        <Dialog open onClose={handleClose} maxWidth="sm" fullWidth disableEscapeKeyDown>
            <Formik initialValues={{ name_persian: "", name_english: "" }} onSubmit={handleSubmit}>
                {({ isSubmitting }) => (
                    <Form>
                        <DialogHeader closeHandler={handleClose}>ثبت عنوان دلخواه</DialogHeader>
                        <DialogContent dividers>
                            <FormGroup>
                                <Alert severity="info">
                                    <Typography fontSize={".9em"}>
                                        درصورتی که عنوان منو مورد نظر در لیست وجود ندارد ، میتوانید از طریق این فرم اقدام به ثبت آن نمایید.
                                    </Typography>
                                </Alert>
                                <Field autoFocus component={TextField} name="name_persian" label="عنوان (فارسی)" required />
                                <Field component={TextField} name="name_english" label="عنوان (انگلیسی)" helperText={"وارد کردن این مقدار اجباری نیست"} />
                            </FormGroup>
                        </DialogContent>
                        <DialogActions>
                            <LoadingButton variant="contained" type="submit" loading={isSubmitting}>ثبت عنوان</LoadingButton>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}