import ApplicationLoading from "components/ApplicationLoading";
import useApi from "hooks/useApi";
import { Fragment, useCallback, useEffect, useRef } from "react";
import { Navigate, Outlet, ScrollRestoration } from "react-router-dom";
import { initialize } from "reducers/workspace";
import { useAppDispatch, useAppSelector } from "redux/store";

function Application() {
	const { loading } = useAppSelector((state) => state.workspace);
	const controller = useRef(new AbortController());

	const api = useApi(controller.current.signal);

	const dispatch = useAppDispatch();

	const loadDashboard = useCallback(async () => {
		try {
			const response = await api("/dashboard");
			dispatch(initialize(response.data));
		} catch (err) {
			console.error(err);
		}
	}, [dispatch]);

	useEffect(() => {
		loadDashboard();
		return () => controller.current.abort();
	}, []);

	return loading ? (
		<ApplicationLoading />
	) : (
		<Fragment>
			<ScrollRestoration
				getKey={(location) => location.pathname}
				storageKey={"scroll-restoration"}
			/>
			<Outlet />
		</Fragment>
	);
}

function AuthorizedOutlet() {
	const authorized = useAppSelector((state) => state.account);

	return authorized === null ? (
		<Navigate to="/auth" replace />
	) : (
		<Application />
	);
}

export default AuthorizedOutlet;
