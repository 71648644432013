import { ListItemText, MenuItem } from "@mui/material";
import { useGetBusinessMenusQuery } from "features/entities/menus";
import { Field } from "formik";
import { Select } from "formik-mui";

export default function BusinessMenusSelectable() {
	const { data } = useGetBusinessMenusQuery(null);
	return (
		<Field size="small" component={Select} name="menu_id" label="منو">
			{data?.map((menu, index: number) => (
				<MenuItem value={menu.id} key={`business-menu-list-item-${index}`}>
					<ListItemText
						primary={menu.reference.name_persian}
						secondary={menu.reference.name_english}
					/>
				</MenuItem>
			))}
		</Field>
	);
}
