import {
	AppBarProps,
	Box,
	LinearProgress,
	AppBar as MuiAppBar,
	Toolbar as MuiToolbar,
	ToolbarProps,
	Typography,
	styled,
	useScrollTrigger,
} from "@mui/material";
import ArrowBackButton from "components/Buttons/ArrowBackButton";
import { MutableRefObject, ReactNode, useRef, Fragment } from "react";

export type TopAppBarProps = {
	title?: string;
	centeredTitle?: boolean;
	primaryActions?: ReactNode;
	secondaryActions?: ReactNode;
	window?: Window | (() => Window);
	arrowBack?: boolean;
	container?: MutableRefObject<HTMLElement | null>;
	loading?: boolean;
	backPath?: string;
};

const AppBar = styled(MuiAppBar)<AppBarProps & { scrolled?: boolean }>(
	({ theme }) => ({
		marginBottom: theme.spacing(1),
		transition: `all .1s cubic-bezier(.17,.67,.83,.67)`,
		backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.default}, transparent)`,
		padding: theme.spacing(2, 2, 0, 2),
	})
);
const Toolbar = styled(MuiToolbar)<ToolbarProps & { scrolled: boolean }>(
	({ theme, scrolled }) => ({
		backgroundColor: scrolled ? theme.palette.primary.dark : "transparent",
		color: scrolled
			? theme.palette.background.paper
			: theme.palette.common.black,
		minHeight: 64,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		transition: `all .1s cubic-bezier(.17,.67,.83,.67)`,
		width: "100%",
		gap: 1,
		borderRadius: theme.spacing(2),
	})
);

export default function TopAppBar(props: TopAppBarProps) {
	const toolbar = useRef<HTMLDivElement>(null);

	const scrolled = useScrollTrigger({ disableHysteresis: true, threshold: 0 });

	return (
		<Fragment>
			<AppBar position="fixed" color="transparent" scrolled={scrolled}>
				<Toolbar ref={toolbar} scrolled={scrolled}>
					{props.arrowBack ? (
						<ArrowBackButton path={props.backPath} />
					) : (
						props.primaryActions
					)}
					{props.title && (
						<Typography
							component="h1"
							fontSize=".9em"
							fontWeight={500}
							sx={{
								...(props.centeredTitle
									? {
											position: "absolute",
											top: "50%",
											left: "50%",
											transform: "translate(-50%,-50%)",}
									: {ml: 1}),
							}}
						>
							{props.title}
						</Typography>
					)}
					<Box flex={1} />
					{props.secondaryActions}
				</Toolbar>
				{props.loading && <LinearProgress />}
			</AppBar>
			<MuiToolbar sx={{ mb: 3 }} />
		</Fragment>
	);
}
