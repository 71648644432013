import { Card, CardContent, List } from "@mui/material";
import PageLayout from "components/PageLayout";
import { useAppSelector } from "redux/store";
import UserBusiness from "types/business";
import BusinessCard from "./BusinessCard";
import NoBusinessFound from "./NoBusinessFound";

export default function SwitchPage() {
	const { businesses } = useAppSelector((state) => state.workspace);

	return (
		<PageLayout
			topAppBar={{
				title: "انتخاب فضای کار",
				centeredTitle: true,
			}}
		>
			<Card>
				<CardContent>
					<List sx={{ pt: 0 }}>
						{businesses?.map((business: UserBusiness, index: number) => (
							<BusinessCard
								key={`bussiness-li-${index}`}
								title={business.brand}
								subdomain={business?.category?.name || business.subdomain}
								id={business.id}
							/>
						))}
						{(!businesses || businesses?.length === 0) && <NoBusinessFound />}
					</List>
				</CardContent>
			</Card>
		</PageLayout>
	);
}
