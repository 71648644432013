import {combineReducers} from "redux";

// reducers
import auth from "../reducers/auth";
import account from "../reducers/account";
import workspace from "../reducers/workspace";
import restApi from "features/entities";

export default combineReducers({
    auth,
    account,
    workspace,
    [restApi.reducerPath]: restApi.reducer
});
