import { MenuItem } from "@mui/material";
import { useGetIconPackageOptionsQuery } from "features/entities/helpers";
import { Field } from "formik";
import { Select } from "formik-mui";
import { IconPackage } from "types/iconPackage";

export default function IconPackageSelect() {
	const { data, isLoading } = useGetIconPackageOptionsQuery(null);

	return (
		<Field
			component={Select}
			id="iconPackage"
			name="ip_id"
			labelId="iconPackage"
			label="پکیج آیکون"
			fullWidth
		>
			{data?.map((iconPackage: IconPackage, index: number) => (
				<MenuItem key={`icon-package-option-${index}`} value={iconPackage.id}>
					{iconPackage.name}
				</MenuItem>
			))}
		</Field>
	);
}
