import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import {
	CardActions,
	CardContent,
	FormControl,
	FormGroup
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import useApi from "hooks/useApi";
import { Settings } from ".";
import PhonesArray from "./PhonesArray";

export default function ContactInformationForm({
	data,
}: {
	data: Settings | null;
}) {
	const api = useApi();

	return (
		<Formik
			initialValues={{
				address: data?.contactInformation?.address || "",
				phones: data?.contactInformation?.phones || [],
				email: data?.contactInformation?.email || ""
			}}
			onSubmit={async (data, { setSubmitting }) => {
				try {
					const response = await api(`/business/settings/contactInformation`, {
						method: "PUT",
						body: JSON.stringify(data),
					});
				} finally {
					setSubmitting(false);
				}
			}}
		>
			<Form>
				<CardContent>
					<FormGroup>
						<Field
							component={TextField}
							name="address"
							label="آدرس"
							multiline
							row={3}
						/>
						<FormControl>
							<PhonesArray />
						</FormControl>
						<Field
							component={TextField}
							name="email"
							label="پست الکترونیک"
							dir="ltr"
							type="email"
						/>
					</FormGroup>
				</CardContent>
				<CardActions>
					<LoadingButton
						type="submit"
						variant="contained"
						startIcon={<Icon icon="radix-icons:update" />}
					>
						بروزرسانی
					</LoadingButton>
				</CardActions>
			</Form>
		</Formik>
	);
}
