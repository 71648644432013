import { MenuItem } from "@mui/material";
import { useGetTemplateOptionsQuery } from "features/entities/helpers";
import { Field } from "formik";
import { Select } from "formik-mui";
import { Template } from "types/template";

export default function TemplateSelect() {
	const { data, isLoading } = useGetTemplateOptionsQuery(null);

	return (
		<Field
			component={Select}
			id="template"
			name="template_id"
			labelId="template"
			label="قالب منو"
			fullWidth
		>
			{data?.map((template: Template, index: number) => (
				<MenuItem key={`template-option-${index}`} value={template.id}>
					{template.name}
				</MenuItem>
			))}
		</Field>
	);
}
