import { Box, styled, useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { clearCredentials } from "reducers/account";
import { useAppDispatch } from "redux/store";
import WelcomeDes from "./Welcome";

const Root = styled("div")(({ theme }) => ({
	width: "100%",
	maxWidth: "100vw",
	height: "100%",
	minHeight: "100vh",
	padding: theme.spacing(1),
	inset: 0,
	overflow: "auto",
	background:
		"linear-gradient(109.6deg, rgb(17, 151, 147) 11.2%, rgb(154, 214, 212) 55.4%, rgb(255, 233, 171) 100.2%)",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	gap: theme.spacing(1),
}));

const Container = styled(Box)(({ theme }) => ({
	maxWidth: theme.breakpoints.values.md,
	width: "100%",
	display: "grid",
	gridTemplateColumns: "max-content 1fr",
	gridTemplateRows: "1fr",
	backgroundColor: theme.palette.background.paper,
	borderRadius: theme.spacing(1),
	padding: theme.spacing(4),
	gap: theme.spacing(2),
	[theme.breakpoints.down("md")]: {
		maxWidth: theme.breakpoints.values.xs,
		gridTemplateColumns: "1fr",
		gridTemplateRows: "auto auto",
		gap: theme.spacing(1),
		["& > *:last-child"]: {
			order: -1,
		},
	},
}));

export default function PrivateLayout() {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(clearCredentials());
	}, []);

	const theme = useTheme();
	const isMedium = useMediaQuery(theme.breakpoints.up("sm"));

	return (
		<Root>
			<Container className="next-shadow">
				<Box
					sx={(theme) => ({
						[theme.breakpoints.up("sm")]: {
							width: "fit-content",
						},
					})}
				>
					<Outlet />
				</Box>
				{isMedium && <WelcomeDes />}
			</Container>
		</Root>
	);
}
