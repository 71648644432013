import { LoadingButton } from "@mui/lab";
import { Collapse, FormControl, FormGroup } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import useApi from "hooks/useApi";
import { enqueueSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setCredentials } from "reducers/account";
import { useAppDispatch } from "redux/store";
import * as yup from "yup";
import { Values } from ".";
import EnterOTP from "./EnterOTP";

const EnterMobile = ({ signal }: { signal: AbortController["signal"] }) => {
	const api = useApi(signal);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const [mode, setMode] = useState<
		"enter-mobile" | "enter-code" | "enter-password"
	>("enter-mobile");

	const handleSubmit = useCallback(
		async (data: Values, { setSubmitting }: any) => {
			try {
				if (mode === "enter-mobile") {
					const response = await api("/auth/login", {
						body: JSON.stringify({ mobile: data.mobile }),
						method: "post",
					});
					if (response.status === 200) {
						// dispatch(storeLoginData(response.data));
						if (response?.data?.code) {
							enqueueSnackbar(response.data.code, { variant: "info" });
						}
						if (response?.data?.mode) {
							setMode("enter-code");
						}
						// navigate(`/auth/verify`);
					}
				}
				if (mode === "enter-code") {
					const response = await api("/auth/verify", {
						body: JSON.stringify({ mobile: data.mobile, code: data.otp }),
						method: "POST",
					});
					const { user, step, tokens } = response.data;
					dispatch(
						setCredentials({
							provider: "sms",
							mobile: user.mobile,
							profile: user.profile,
							tokens,
						})
					);
					if (step === "COMPLETE_PROFILE") {
						return navigate("/auth/complete", { replace: true });
					}
					return navigate("/");
				}
			} catch (err) {
				console.error(err);
			} finally {
				setSubmitting(false);
			}
		},
		[mode, dispatch, navigate]
	);

	return (
		<Formik
			initialValues={
				{
					mobile: "",
					otp: "",
				} as Values
			}
			onSubmit={handleSubmit}
			validationSchema={yup.object().shape({
				mobile: yup
					.string()
					.matches(/09[0-9]{9}/, { message: "فرمت شماره صحیح نمی‌باشد" })
					.required("وارد کردن موبایل الزامیست"),
			})}
		>
			{({ isSubmitting }) => (
				<Form>
					<FormGroup>
						<FormControl>
							<Field
								component={TextField}
								dir={"ltr"}
								type={"tel"}
								name={"mobile"}
								autoFocus
								autoComplete={false}
								inputProps={{
									placeholder: "09XX XXX XXXX",
									autoComplete: false,
									required: true,
								}}
								focused
							/>
						</FormControl>
						<Collapse unmountOnExit in={mode === "enter-code"}>
							<EnterOTP />
						</Collapse>
						<Collapse unmountOnExit in={mode === "enter-password"}>
							<Field
								component={TextField}
								name="password"
								id="password"
								aria-labeled="password"
								dir="ltr"
							/>
						</Collapse>
						<FormControl sx={{ mt: 1 }}>
							<LoadingButton
								type="submit"
								variant={"contained"}
								size={"small"}
								loading={isSubmitting}
							>
								{mode === "enter-mobile" && "دریافت کد"}
								{mode === "enter-code" && "بررسی"}
								{mode === "enter-password" && "ورود"}
							</LoadingButton>
						</FormControl>
					</FormGroup>
				</Form>
			)}
		</Formik>
	);
};

export default EnterMobile;
