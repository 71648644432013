import { Box, Stack, styled } from "@mui/material";

const Cube = styled(Box)(({ theme }) => ({
	aspectRatio: "1/1",
	display: "block",
	position: "relative",
	width: theme.spacing(4),
	borderRadius: theme.spacing(1),
}));

const ColorDoublePalette = ({ base, dark }: { base: string; dark: string }) => {
	return (
		<Box p={1} border={`1px solid #333`}>
			<Stack
				direction={"column"}
				spacing={1}
				alignItems={"center"}
				justifyContent={"center"}
			>
				<Cube bgcolor={base} />
				{dark && <Cube bgcolor={dark as string} />}
			</Stack>
		</Box>
	);
};

export default ColorDoublePalette;
