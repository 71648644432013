import { Chip, CircularProgress } from "@mui/material";
import StyledSwitch from "components/Form/StyledSwtich";
import {
	useToggleMenuItemVisibilityMutation
} from "features/entities/menuItems";

export type ToggleVisibilityProps = {
	id: number;
	menu_id: number;
	visibility: boolean;
};

export default function ToggleVisibility(props: ToggleVisibilityProps) {
	const { id, menu_id, visibility } = props;

	const [toggleVisibility, { isLoading }] = useToggleMenuItemVisibilityMutation();

	return isLoading ? <CircularProgress size={16} /> : (
		<StyledSwitch
			onChange={() => toggleVisibility({ id, menu_id })}
			checked={visibility}
		/>
	);
}
