import { Box, styled } from "@mui/material";

const Structure = styled(Box)(({ theme }) => ({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 0,
    flexGrow: 0,
    position: "relative",
    overflow: "overlay"
}));

export default Structure;