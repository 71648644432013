const useColors = () => {
	function getDarkColor(hexColor: string) {
        if(!(new RegExp(/#[a-zA-Z0-9]{6}/).test(hexColor))) {
            return new Error("رنگ وارد شده صحیح نیست!");
        }
		// convert hex color to RGB
		const r = parseInt(hexColor.substring(1, 3), 16);
		const g = parseInt(hexColor.substring(3, 5), 16);
		const b = parseInt(hexColor.substring(5, 7), 16);

		// calculate the average of the RGB values
		const average = (r + g + b) / 3;

		// calculate the dark grey color
		const darkGrey = Math.floor(average * 0.5);

		// convert dark grey to hex color
		const darkGreyHex = darkGrey.toString(16).padStart(2, "0");

		// return dark grey hex color
		return `#${darkGreyHex}${darkGreyHex}${darkGreyHex}`;
	}

	return {
		getDarkColor,
	};
};

export default useColors;
