import type { Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { enqueueSnackbar } from "notistack";
import { clearCredentials } from "reducers/account";
import { RootState } from "redux/store";

export const rtkQueryErrorLogger: Middleware =
	(api: MiddlewareAPI) => (next) => (action) => {
		if (action?.payload?.originalStatus === 401) {
			enqueueSnackbar("وارد اکانت خود شوید.", { variant: "warning" });
			api.dispatch(clearCredentials());
		}

		if (action?.payload?.data?.message) {
			enqueueSnackbar(action.payload.data.message, { variant: "error" });
		}
		if (action?.payload?.message) {
			enqueueSnackbar(action.payload.message, { variant: "success" });
		}

		return next(action);
	};

const api = createApi({
	baseQuery: fetchBaseQuery({
		credentials: "same-origin",
		baseUrl: process.env.REACT_APP_BASE_URL,
		prepareHeaders(headers, api) {
			const account = (api.getState() as RootState).account;
			headers.set("Authorization", "Bearer " + account?.tokens.accessToken);
		},
	}),
	endpoints: () => ({}),
	keepUnusedDataFor: 300,
	refetchOnReconnect: true,
	reducerPath: "@restAPI",
	tagTypes: [
		"BUSINESS_MENUS",
		"HELPER_TEMPLATES",
		"HELPER_ICON_PACKAGES",
		"HELPER_MENUS",
		"MENUS",
		"MENU_ITEMS",
		"COMMENTS",
		"PREFERENCESA",
	],
});

export default api;
