import { Card, LinearProgress } from "@mui/material";
import PageLayout from "components/PageLayout";
import useApi from "hooks/useApi";
import useColors from "hooks/useColors";
import { useCallback, useEffect, useState } from "react";
import { BusinessTemplateConfig } from "types/business";
import TemplateConfigForm from "./TemplateConfigForm";
import FormInputs from "./FormInputs";

export default function BusinessTemplateSettings() {
	const [templateConfig, setConfig] = useState<null | BusinessTemplateConfig>(
		null
	);

	const api = useApi();
	const { getDarkColor } = useColors();

	const loadTemplateConfig = useCallback(async () => {
		const response = await api("/business/settings/template/config");
		if (response.data) {
			setConfig(response.data);
		}
	}, [api]);

	useEffect(() => {
		if (!templateConfig) {
			loadTemplateConfig();
		}
	}, [templateConfig]);

	return (
		<PageLayout
			topAppBar={{ arrowBack: true, title: "تنظیمات نمایش" }}
			backPath="/dashboard"
		>
			<Card>
				{templateConfig ? (
					<TemplateConfigForm defaultValue={templateConfig}>
						<FormInputs />
					</TemplateConfigForm>
				) : (
					<LinearProgress />
				)}

				{/* {business?.logo && (
						<ToggleButtonGroup
							value={business.palette}
							exclusive
							onChange={(event, value) =>
								console.log("selected color palette: ", value)
							}
							aria-label="Template color palette"
						>
							{business.logo.colorPalette.split(",").map((hex, index) => {
								const dark = getDarkColor(hex) as string;
								return (
									<ToggleButton
										value={[hex, dark]}
										key={`colors-palette-${index}`}
									>
										<ColorDoublePalette base={hex} dark={dark} />
									</ToggleButton>
								);
							})}
						</ToggleButtonGroup>
					)} */}
			</Card>
		</PageLayout>
	);
}
