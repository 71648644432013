import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import { FormGroup } from "@mui/material";
import { useSFD } from "components/SwipeableFormDrawer/context";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import useApi from "hooks/useApi";
import { nanoid } from "nanoid";
import { useEffect } from "react";

export default function ReportTechnicalIssueForm() {

    const api = useApi();
    const { handleCloseDrawer,handleMountingDrawer } = useSFD();

    useEffect(() => {
        handleMountingDrawer();
    }, []);

	return (
		<Formik
			initialValues={{
				track_id: nanoid(),
				problem: "",
			}}
            onSubmit={async (data, { setSubmitting }) => {
                try {
                    const response = await api(`/profile/password/set`, {
                        body: JSON.stringify(data),
                        method: "post"
                    });
                } finally {
                    setSubmitting(false);
                }
            }}
		>
            {({ isSubmitting }) => (
                <Form>
				<FormGroup>
					<Field
						component={TextField}
						type="text"
						name="problem"
						label="متن گزارش"
						multiline
						rows={5}
					/>
					<LoadingButton startIcon={<Icon icon="mingcute:send-plane-line" />} loading={isSubmitting} type="submit" variant="contained" color="secondary">
						ارسال
					</LoadingButton>
				</FormGroup>
			</Form>
            )}
		</Formik>
	);
}
