import PageLayout from "components/PageLayout";
import BasicInformationForm from "./BasicInformation";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {
	Card,
	CardHeader,
	Divider,
	LinearProgress,
	cardClasses,
	cardContentClasses,
	styled,
} from "@mui/material";
import SocialAccountsForm from "./SocialAccounts";
import { Icon } from "@iconify/react";
import { useCallback, useEffect, useRef, useState } from "react";
import useApi from "hooks/useApi";
import { Media } from "types/media";
import BusinessCategory from "types/businesscategory";
import {
	BusinessContactInformation,
	BusinessSocialAccounts,
} from "types/business";
import ContactInformationForm from "./ContactInformation";

const StyledGrid = styled(Grid2)(({ theme }) => ({
	[theme.breakpoints.up("sm")]: {
		[`& .${cardClasses.root}`]: {
			height: "100%",
			[`& .${cardContentClasses.root}`]: {
				height: "-webkit-fill-available",
			},
		},
	}
}));

export type Settings = {
	id: string;
	brand: string;
	subdomain: string;
	domain: string;
	activated: boolean;
	updatedAt: string;
	category_id: number;
	logo_id: number;
	logo: Media | null;
	category: BusinessCategory;
	socialAccounts: BusinessSocialAccounts;
	contactInformation: BusinessContactInformation;
};

export default function BusinessSettings() {
	const [preferences, setPreferences] = useState<null | Settings>(null);

	const api = useApi();
	const loadSettings = useCallback(async () => {
		const { data } = await api("/business/settings");
		setPreferences(data);
	}, [api]);

	useEffect(() => {
		if(preferences === null) {
			loadSettings();
		}
	}, []);

	return (
		<PageLayout
			topAppBar={{
				arrowBack: true,
				title: "تنظیمات",
			}}
		>
			<Grid2 container spacing={3} alignItems="stretch">
				<StyledGrid xs={12} md={6} lg={4}>
					<Card>
						<CardHeader
							avatar={<Icon icon="mdi:information-slab-box-outline" />}
							title="درباره برند"
						/>
						<Divider />
						{preferences ? <BasicInformationForm data={preferences} /> : <LinearProgress />}
						
					</Card>
				</StyledGrid>
				<StyledGrid xs={12} md={6} lg={4}>
					<Card>
						<CardHeader
							avatar={<Icon icon="mdi:instagram" />}
							title="شبکه های اجتماعی"
						/>
						<Divider />
						{preferences ? <SocialAccountsForm data={preferences} /> : <LinearProgress />}
					</Card>
				</StyledGrid>
				<StyledGrid xs={12} md={6} lg={4}>
					<Card>
						<CardHeader
							avatar={<Icon icon="mdi:address-marker-outline" />}
							title="مسیر های ارتباطی"
						/>
						<Divider />
						{preferences ? <ContactInformationForm data={preferences} /> : <LinearProgress />}
					</Card>
				</StyledGrid>
			</Grid2>
		</PageLayout>
	);
}
