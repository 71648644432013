import { Avatar, Collapse } from "@mui/material";
import { useFormikContext } from "formik";
import { useEffect, useRef } from "react";

export default function FormikImagePreview({
	fieldname,
}: {
	fieldname: string;
}) {
	const imageUrl = useRef<string | null>(null);

	const { values } = useFormikContext<any>();
	const open = Boolean(imageUrl);

	useEffect(() => {
		if (values[fieldname]) {
			if (values[fieldname].filename) {
				imageUrl.current = values[fieldname].filename;
			}
			if (values[fieldname] instanceof File) {
				imageUrl.current = URL.createObjectURL(values[fieldname]);
			}
		}
	}, [values[fieldname]]);
	return (
		<Collapse in={open} unmountOnExit>
			<Avatar
				src={imageUrl.current as unknown as string}
				alt="Image Preview"
				sx={{ width: 128, height: 128, mx: "auto" }}
				variant="rounded"
			/>
		</Collapse>
	);
}
