import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "redux/store";

export default function JustInWorkspace() {
	const { business, businesses } = useAppSelector((state) => state.workspace);

	if (!business && businesses?.length === 0) {
		return <Navigate to="/no-business" replace />;
	} else if (!business && businesses && businesses.length > 0) {
		return <Navigate to={"/switch"} replace />;
	} else {
		return <Outlet />;
	}
}
