import PageLayout from "components/PageLayout";
import CommentsList from "./CommentsList";

export default function CustomerComments() {
	return (
		<PageLayout
			backPath="/dashboard"
			topAppBar={{
				title: "بازخورد کاربران",
				arrowBack: true,
			}}
		>
			<CommentsList />
		</PageLayout>
	);
}
