import { Icon } from "@iconify/react";
import {
	Card,
	CardActionArea,
	CardActions,
	CardHeader,
	CircularProgress,
	styled,
	SwitchProps,
	Typography,
} from "@mui/material";
import BusinessLogo from "components/Business/BusinessLogo";
import StyledSwitch from "components/Form/StyledSwtich";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const StyledCard = styled(Card)(({ theme }) => ({
	width: "100%",
	backgroundImage: theme.palette.background.paper,
	border: `none`,
	boxShadow:
		"0 0 0 1px rgb(0 0 0 / 7%), 0 2px 4px rgb(0 0 0 / 5%), 0 12px 24px rgb(0 0 0 / 5%)",
	borderRadius: theme.spacing(0.5),
}));

const FlexCardActions = styled(CardActions)(() => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	minHeight: 0,
	height: "fit-content",
}));

interface Props {
	pathToNavigate?: string;
	onClickAction?: () => unknown;
	title: string;
	subheader?: string;
	switchActionText?: string;
	onSwitch?: SwitchProps["onChange"];
	switchState?: boolean;
	thumbnail?: string;
	thumbnailCaption?: string;
	activeItem?: boolean;
	loading?: boolean;
}

const StyledCardOne = (props: Props) => {
	const {
		pathToNavigate,
		onClickAction,
		title,
		subheader,
		onSwitch,
		switchActionText,
		switchState,
		thumbnail,
		thumbnailCaption,
		activeItem,
		loading
	} = props;
	const navigate = useNavigate();

	const handleClick = useCallback(() => {
		if (pathToNavigate) {
			return navigate(pathToNavigate);
		}
		if (onClickAction) {
			return onClickAction();
		}
		return console.log("no action found!");
	}, []);

	return (
		<StyledCard>
			<CardActionArea onClick={handleClick}>
				<CardHeader
					title={title}
					subheader={subheader}
					avatar={thumbnail ? <BusinessLogo src={thumbnail} caption={thumbnailCaption} active={activeItem} /> : undefined}
					titleTypographyProps={{
						fontSize: "1.5em",
						fontWeight: "bold",
						color: "common.black",
					}}
					subheaderTypographyProps={{
						fontSize: "1.2em",
						fontWeight: "light",
						color: "GrayText",
					}}
					action={loading ? <CircularProgress size={16} /> : <Icon icon="material-symbols:more-vert" color="#d0d0d0" />}
				/>
			</CardActionArea>
			{(switchActionText || onSwitch) && (
				<FlexCardActions>
					{switchActionText && (
						<Typography
							component="span"
							fontSize=".6em"
							fontWeight={"light"}
							color={"GrayText"}
						>
							{switchActionText}
						</Typography>
					)}
					<StyledSwitch onChange={onSwitch} checked={switchState} />
				</FlexCardActions>
			)}
		</StyledCard>
	);
};

export default StyledCardOne;
