
import { BusinessMenu } from "types/BusinessMenu";
import api from "./index";

const menusApi = api.injectEndpoints({
	endpoints: (build) => ({
		getBusinessMenus: build.query<BusinessMenu[], any>({
			query: () => "/business/menus",
			keepUnusedDataFor: 30,
			providesTags: ["MENUS"],
		}),
		getBusinessMenu: build.query<BusinessMenu, { menu_id: string|number; }>({
			query: ({menu_id}) => `/business/menus/${menu_id}`,
			keepUnusedDataFor: 0,
			providesTags: (result) => [{ type: "MENUS", id: result?.id }]
		}),
		addBusinessMenu: build.mutation({
			query: (body) => ({
				url: "/business/menus",
				body,
				method: "POST"
			}),
			invalidatesTags: ["MENUS"]
		}),
		updateBusinessMenu: build.mutation({
			query: ({ id, ...body }) => ({
				url: `/business/menus/${id}`,
				body,
				method: "PUT",
			}),
			invalidatesTags: ["MENUS"],
		}),
		deleteBusinessMenu: build.mutation({
			query: (id) => ({
				url: `/business/menus/${id}`,
				method: "DELETE"
			}),
			invalidatesTags: ["MENUS"],
		}),
		toggleVisibility: build.mutation({
			query: (id) => ({
				url: `/business/menus/${id}`,
				method: "PATCH",
			}),
			invalidatesTags: ["MENUS"]
		})
	}),
});

export const {
	useGetBusinessMenusQuery,
	useGetBusinessMenuQuery,
	useAddBusinessMenuMutation,
	useUpdateBusinessMenuMutation,
	useDeleteBusinessMenuMutation,
	useToggleVisibilityMutation } = menusApi;

export default menusApi;
