import { createTheme, ThemeOptions } from "@mui/material/styles";

let theme = createTheme({
	direction: "rtl",
	typography: {
		fontFamily: "Vazirmatn, sans-serif",
		h1: {
			fontWeight: 700,
			fontSize: "24px",
		},
		fontSize: 8,
		htmlFontSize: 8,
		fontWeightLight: 100,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		fontWeightBold: 600,
	},
	palette: {
		primary: {
			main: "#5171a1",
			contrastText: "#fff",
		},
		secondary: {
			main: "#151a23",
			contrastText: "#fff",
		},
		background: {
			default: "#e9ecef",
			paper: "#fff",
		},
		common: {
			black: "#353535",
			white: "#fff",
		},
	},
	breakpoints: {
		values: {
			xs: 360,
			sm: 480,
			md: 768,
			lg: 1280,
			xl: 1600
		}
	}
});

theme = createTheme(theme, {
	components: {
		MuiTextField: {
			defaultProps: {
				size: "small",
			},
		},
		MuiButton: {
			defaultProps: {
				variant: "contained",
				size: "small",
				disableElevation: true,
			},
			styleOverrides: {
				sizeSmall: {
					fontWeight: "light",
					fontSize: ".7em"
				},
				root: {
					minWidth: "unset"
				}
			}
		},
		MuiAppBar: {
			styleOverrides: {
				colorPrimary: {
					background: theme.palette.primary.dark,
				},
			},
			defaultProps: {
				elevation: 0,
			},
		},
		MuiFormGroup: {
			styleOverrides: {
				root: {
					gap: 16,
				},
			},
		},
		MuiTooltip: {
			defaultProps: {
				arrow: true,
			},
		},
		MuiCard: {
			styleOverrides: {
				root: {
					boxShadow:
						"0 0 0 1px rgba(0,0,0,.07), 0 2px 4px rgba(0,0,0,.05), 0 6px 12px rgba(0,0,0,.05)",
				},
			},
		},
		MuiCardActions: {
			styleOverrides: {
				root: {
					justifyContent: "flex-end",
				},
			},
		},
		MuiIconButton: {
			defaultProps: {
				color: "inherit"
			}
		},
		MuiCssBaseline: {
			styleOverrides(theme) {
				return {
					a: {
						textDecoration: "none",
						color: "inherit"
					},
					".next-shadow": {
						boxShadow: "0 0 0 1px rgba(0,0,0,.07), 0 2px 4px rgba(0,0,0,.05), 0 12px 24px rgba(0,0,0,.05)"
					},
					"::-webkit-scrollbar": {
						backgroundColor: "rgba(0,0,0,.092)",
						width: 5
					},
					"::-webkit-scrollbar-button": {
						display: "none"
					},
					"::-webkit-scrollbar-thumb": {
						borderRadius: theme.spacing(2),
						backgroundColor: theme.palette.secondary.light,
						"&:hover": {
							backgroundColor: theme.palette.primary.dark,
						}
					},
				}
			},
		},
	},
} as ThemeOptions);

export default theme;
