import { ListItem } from "@mui/material";
import StyledCardOne from "components/Cards/StyledCardOne";
import { useToggleVisibilityMutation } from "features/entities/menus";
import { useCallback } from "react";
import { BusinessMenu } from "types/BusinessMenu";

const BusinessMenuItem = (props: BusinessMenu) => {
	const { id, reference, items_count, visibility } = props;

	const [exec, api] = useToggleVisibilityMutation();

	const toggleVisibility = useCallback(async () => {
		try {
			await exec(id).unwrap();
		} catch (err) {
			console.error(err);
		}
	}, [id]);

	return (
		<StyledCardOne
			title={reference.name_persian}
			subheader={`${items_count} آیتم`}
			pathToNavigate={`/dashboard/menus/${id}`}
			switchActionText="نمایش به مشتریان"
			switchState={visibility}
			onSwitch={toggleVisibility}
			loading={api.isLoading}
			thumbnail={`https://nextmenu.ir/icons/flat-colorful/${reference.name_english}.svg`}
		/>
	);
};

export default BusinessMenuItem;
