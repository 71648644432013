import AddBusinessMenu from "features/forms/AddBusinessMenu";
import AddBusinessMenuItem from "features/forms/BusinessMenuItem/AddBusinessMenuItem";
import EditBusinessMenuItem from "features/forms/BusinessMenuItem/EditBusinessMenuItem";
import useApi from "hooks/useApi";
import { enqueueSnackbar } from "notistack";
import BusinessMenuItems from "pages/PrivatePages/BusinessMenuItems";
import BusinessMenus from "pages/PrivatePages/BusinessMenus";
import CustomerComments from "pages/PrivatePages/CustomerCommnets";
import Dashboard from "pages/PrivatePages/Dashboard";
import UpdateUserProfile from "pages/PrivatePages/UpdateUserProfile";
import UserProfile from "pages/PrivatePages/UserProfile";
import RegisterBusiness from "pages/RegisterBusiness";
import {
	createBrowserRouter,
	Navigate,
	Outlet,
	redirect,
	RouteObject,
	RouterProvider,
} from "react-router-dom";
import { BusinessMenuItem } from "types/BusinessMenuItem";
import PublicLayout from "./pages/PrivatePages/Layout";
import CompleteProfile from "./pages/PublicPages/Auth/CompleteProfile";
import LoginPage from "./pages/PublicPages/Auth/LoginPage";
import VerificationPage from "./pages/PublicPages/Auth/VerificationPage";
import PrivateLayout from "./pages/PublicPages/Layout";
import Welcome from "./pages/PublicPages/Welcome";
import Intro from "pages/PublicPages/Intro";
import UpdateUserPassword from "features/forms/UpdateUserPassword";
import ReportTechnicalIssue from "features/forms/ReportTechnicalIssue";
import JustInWorkspace from "components/JustInWorkspace";
import NotfoundPage from "pages/globals/Notfound";
import NoBusiness from "pages/globals/NoBusiness";
import SwitchPage from "components/BusinessSwitcher/SwitchPage";
import BusinessSettings from "pages/PrivatePages/BusinessSettings";
import BusinessTemplateSettings from "pages/PrivatePages/BusinessThemeSettings";

function AppRoutes() {
	const api = useApi();

	const routes: RouteObject[] = [
		{
			path: "/",
			element: <PublicLayout />,
			id: "root",
			children: [
				{
					index: true,
					element: <Navigate to="/dashboard" replace />,
				},
				{
					path: "/profile",
					element: <UserProfile />,
					id: "user-profile-view",
					children: [
						{
							path: "password/update",
							element: <UpdateUserPassword />,
							id: "update-user-password",
						},
						{
							path: "report",
							element: <ReportTechnicalIssue />,
							id: "report-technical-issue",
						},
					],
				},
				{
					path: "/profile/edit",
					element: <UpdateUserProfile />,
					id: "update-user-profile",
				},
				{
					path: "dashboard",
					id: "dashboard-parent",
					element: <JustInWorkspace />,
					children: [
						{
							index: true,
							element: <Dashboard />,
							id: "dashboard",
						},
						{
							path: "settings",
							id: "business-settings",
							element: <BusinessSettings />,
						},
						{
							path: "settings/template",
							id: "business-template-settings",
							element: <BusinessTemplateSettings />,
						},
						{
							path: "menus",
							element: <BusinessMenus />,
							id: "business-menus",
							children: [
								{
									path: "add",
									element: <AddBusinessMenu />,
									id: "add-business-menu",
								},
							],
						},
						{
							path: "menus/:menu_id",
							id: "business-menu",
							element: <BusinessMenuItems />,
							children: [
								{
									path: "items/add",
									id: "add-business-menu-item",
									element: <AddBusinessMenuItem />,
								},
								{
									path: "items/:itemID",
									id: "edit-business-menu-item",
									element: <EditBusinessMenuItem />,
									loader: async ({ params, request }) => {
										try {
											const response = await api(
												`/business/menus/${params.menu_id}/items/${params.itemID}`,
												{
													signal: request.signal,
												}
											);
											return response.data as BusinessMenuItem;
										} catch (err) {
											throw redirect(`/dashboard/menus/${params.menu_id}`);
										}
									},
								},
							],
						},
						{
							path: "comments",
							element: <CustomerComments />,
							id: "business-customer-comments",
							loader: async ({ request }) => {
								try {
									const response = await api("/business/comments", {
										signal: request.signal,
									});
									return response.data;
								} catch (err) {
									enqueueSnackbar("امکان دریافت بازخورد ها وجود ندارد!", {
										variant: "error",
									});
									throw redirect("/dashboard");
								}
							},
						},
					],
				},
			],
		},
		{
			path: "/auth",
			element: <PrivateLayout />,
			id: "auth",
			hasErrorBoundary: true,
			children: [
				{
					index: true,
					element: <Navigate to="/auth/login" replace />,
				},
				{
					path: "login",
					element: <LoginPage />,
					id: "auth-login",
				},
				{
					path: "verify",
					element: <VerificationPage />,
					id: "auth-verify",
				},
				{
					path: "complete",
					element: <CompleteProfile />,
					id: "auth-complete-profile",
				},
			],
		},
		{
			path: "welcome",
			element: <Welcome />,
		},
		{
			path: "submit-business",
			element: <RegisterBusiness />,
		},
		{
			path: "notfound",
			element: <NotfoundPage />,
			id: "notfound",
		},
		{
			path: "no-business",
			element: <NoBusiness />,
			id: "no-business",
		},
		{
			path: "switch",
			element: <SwitchPage />,
			id: "switch-business",
		},
		{
			path: "*",
			element: <Navigate to="/notfound" replace />,
		},
	];

	const router = createBrowserRouter(routes);
	return <RouterProvider router={router} />;
}

export default AppRoutes;
