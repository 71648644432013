import SwipeableFormDrawer from "components/SwipeableFormDrawer";
import AddBusinessMenuForm from "./AddBusinessMenuForm";

const AddBusinessMenu = () => {

	return (
		<SwipeableFormDrawer>
			<AddBusinessMenuForm />
		</SwipeableFormDrawer>
	);
};

export default AddBusinessMenu;
