import BottomAppBar, { BottomAppBarProps } from "components/BottomAppBar";
import TopAppBar, { TopAppBarProps } from "components/TopAppBar";
import { ReactNode } from "react";
import Body from "./Body";
import Structure from "./styles/structure";

export type PageLayoutProps = {
	children: ReactNode;
	bottomAppBar?: BottomAppBarProps;
	topAppBar?: TopAppBarProps;
	loading?: boolean;
	backPath?: string;
};

function PageLayout(props: PageLayoutProps) {
	return (
		<Structure>
			{props.topAppBar && (
				<TopAppBar
					loading={props.loading}
					backPath={props.backPath}
					{...props.topAppBar}
				/>
			)}
			<Body>{props.children}</Body>
			{props.bottomAppBar && <BottomAppBar {...props.bottomAppBar} />}
		</Structure>
	);
}

export default PageLayout;
