import { Field, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import { memo, useMemo } from "react";
import * as Converter from "persian-currency-converter";
import { InputAdornment, Typography } from "@mui/material";

function PriceField() {
	const { values } = useFormikContext<any>();

	const separated = useMemo(
		() =>
			`${Converter.convertToPersianNum(
				Converter.threeDigitSeparator(values.price)
			)} ریال`,
		[values.price]
	);

	return (
		<Field
			component={TextField}
			name="price"
			label="قیمت واحد"
			dir="ltr"
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<Typography variant="subtitle2" sx={{ float: "right" }}>
							{separated}
						</Typography>
					</InputAdornment>
				),
			}}
		/>
	);
}

export default memo(PriceField);
