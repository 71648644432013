import { Icon } from "@iconify/react";
import {
	Box,
	Button,
	Card,
	CardContent,
	Chip,
	Divider,
	Link,
	List,
	Skeleton,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import BusinessLogo from "components/Business/BusinessLogo";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/store";
import BusinessAction from "./components/BusinessAction";
import BusinessSummaryItem from "./components/BusinessSummaryItem";

const BusinessSummary = () => {
	return (
		<Stack
			direction={"row"}
			alignItems="center"
			justifyContent={"space-around"}
			divider={<Divider flexItem orientation="vertical" />}
			spacing={2}
			mx="auto"
			width="max-content"
		>
			<BusinessSummaryItem label="تعداد منو" count={4} />
			<BusinessSummaryItem label="تعداد آیتم" count={31} />
			{/* <BusinessSummaryItem label="تصویر" count={27} /> */}
		</Stack>
	);
};

const BusinessActions = () => {
	return (
		<>
			<List sx={{ mt: 2 }}>
				<BusinessAction
					label="بازخورد کاربران"
					iconName="uil:comments"
					divider
					href="/dashboard/comments"
				/>
				<BusinessAction
					label="تنظیمات نمایش"
					iconName="arcticons:galaxy-themes"
					divider
					href="/dashboard/settings/template"
				/>
				<BusinessAction
					label="تنظیمات"
					iconName="material-symbols:settings"
					href="/dashboard/settings"
				/>
			</List>
		</>
	);
};

const BusinessDashboard = () => {
	const navigate = useNavigate();

	const { loading, business } = useAppSelector((state) => state.workspace);

	return (
		<Fragment>
			<Box>
				<Card elevation={0}>
					<CardContent>
						<Stack direction={"row"} spacing={5} alignItems="center" mb={2}>
							<BusinessLogo active size={64} src={business?.logo?.url} />
							<Box flex={1}>
								<BusinessSummary />
							</Box>
						</Stack>
						{loading ? (
							<Skeleton animation="wave" width={"60%"} height={"1.5em"} />
						) : (
							<Typography
								component="h1"
								fontSize={".8em"}
								color="common.black"
								fontWeight="bold"
							>
								{business?.brand}
							</Typography>
						)}
						{loading ? (
							<Skeleton animation="wave" width={"20%"} height={"1em"} />
						) : (
							<Typography
								mb={2}
								component="span"
								fontSize={".7em"}
								color="GrayText"
								display={"block"}
								fontWeight={300}
							>
								{business?.category?.name}
							</Typography>
						)}
						{business?.contactInformation?.address && (
							<>
								<Divider sx={{ mb: 1 }} />
								<Typography
									component={"div"}
									fontSize={12}
									color="GrayText"
									display="flex"
									gap={1}
									gutterBottom
								>
									آدرس: <address>{business.contactInformation.address}</address>
								</Typography>
							</>
						)}

						{business?.contactInformation?.phones && (
							<Typography
								component={"div"}
								fontSize={12}
								color="GrayText"
								display="flex"
								gap={1}
								alignItems="center"
								gutterBottom
							>
								تلفن های تماس:{" "}
								<>
									{business.contactInformation.phones?.map((phone, index) => (
										<Link
											href={`tel:${phone}`}
											key={`business-phones-item-${index}`}
										>
											{phone}
										</Link>
									))}
								</>
							</Typography>
						)}

						<Stack spacing={0.5} direction="row">
							<Button fullWidth onClick={() => navigate(`/dashboard/menus`)}>
								مدیریت منوها
							</Button>
							<Button
								fullWidth
								onClick={() => window?.open("test", "_blank")}
								variant="outlined"
							>
								مشاهده منو
							</Button>
							{business?.socialAccounts?.ig && (
								<Button
									LinkComponent={"a"}
									href={business.socialAccounts.ig}
									variant="outlined"
									color="secondary"
								>
									<Icon icon="ph:instagram-logo" fontSize={24} />
								</Button>
							)}
							{business?.socialAccounts?.foursquare && (
								<Button
									LinkComponent={"a"}
									href={business.socialAccounts.foursquare}
									variant="outlined"
									color="secondary"
								>
									<Icon icon="tabler:brand-foursquare" fontSize={24} />
								</Button>
							)}
							<Tooltip title="دریافت QR کد">
								<Button sx={{ width: "fit-content" }} variant="outlined">
									<Icon icon="tabler:qrcode" />
								</Button>
							</Tooltip>
						</Stack>
					</CardContent>
				</Card>
				<BusinessActions />
			</Box>
		</Fragment>
	);
};

export default BusinessDashboard;
