import { Icon, IconProps } from "@iconify/react";
import {
	BottomNavigation,
	BottomNavigationAction as MuiBottomNavigationAction,
	bottomNavigationActionClasses,
	styled,
	useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const StyledIcon = (props: IconProps) => {
	return <Icon {...props} width={24} color={"inherit"} />;
};

const BottomNavigationAction = styled(MuiBottomNavigationAction)(
	({ theme }) => ({
		minWidth: 0,
		width: "fit-content",
		color: theme.palette.primary.light,
		flexDirection: "row",
		flex: "unset",
		gap: theme.spacing(1),
		padding: "unset",
		transition: "all .3s cubic-bezier(.2, .1, .2, .175)",
		height: "fit-content",
		borderRadius: theme.spacing(3),
		[`& .${bottomNavigationActionClasses.label}`]: {
			width: 0,
			fontSize: "1em",
			fontWeight: "bold"
		},
        [`&.${bottomNavigationActionClasses.selected}`]: {
			padding: theme.spacing(.5, 1.5, .5, .5),
			backgroundColor: theme.palette.primary.main,
            color: "#fff",
            // color: theme.palette.secondary.dark,
			[`& .${bottomNavigationActionClasses.label}`]: {
				width: "inherit"
			}
        },
	})
);

export default function AppNavigation() {
	const [value, setValue] = useState("dashboard");

	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const extractedPathname = location.pathname.split("/");
		setValue(extractedPathname[1]);
	}, [location.pathname]);

	return (
		<BottomNavigation
			sx={{ bgcolor: "transparent", alignItems: "center", gap: theme => theme.spacing(1) }}
			value={value}
			onChange={(event, value) => setValue(value)}
		>
			<BottomNavigationAction
				label="داشبورد"
				value="dashboard"
				icon={<StyledIcon icon="ri:dashboard-3-line" />}
				onClick={() => navigate("/dashboard")}
				
			/>
			<BottomNavigationAction
				label="پشتیبانی"
				value={"support"}
				icon={<StyledIcon icon="ic:round-support" />}
			/>
			<BottomNavigationAction
				label="پروفایل"
				value="profile"
				icon={<StyledIcon icon="iconoir:profile-circle" />}
				onClick={() => navigate("/profile", { replace: true })}
			/>
		</BottomNavigation>
	);
}
