import {
	Alert,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import useColors from "hooks/useColors";
import { useAppSelector } from "redux/store";
import { BusinessTemplateConfig } from "types/business";
import ColorDoublePalette from "./ColorDoublePalette";
import { useEffect } from "react";

export default function ColorPaletteToggler() {
	const logo = useAppSelector((state) => state.workspace.business?.logo);

	const { setFieldValue, values } = useFormikContext<BusinessTemplateConfig>();
	const { getDarkColor } = useColors();

	useEffect(() => {
		console.log([values.primary_color, values.background_color]);
	}, [values.background_color, values.primary_color]);

	return logo ? (
		<ToggleButtonGroup
			value={`${values.primary_color},${values.background_color}`}
			exclusive
			onChange={(event, value) => {
                const [primary, bg] = value.split(",");
				setFieldValue("primary_color", primary);
				setFieldValue("background_color", bg);
			}}
			sx={{ mx: "auto" }}
			aria-label="Template color palette"
		>
			{logo.colorPalette.split(",").map((hex, index) => {
				const dark = getDarkColor(hex) as string;
				return (
					<ToggleButton
						value={`${hex},${dark}`}
						key={`colors-palette-${index}`}
					>
						<ColorDoublePalette base={hex} dark={dark} />
					</ToggleButton>
				);
			})}
		</ToggleButtonGroup>
	) : (
		<Alert severity="error">
			<Typography variant="subtitle1" component="span">
				برای تعیین پالت رنگی ابتدا لوگو برند خود را آپلود نمایید
			</Typography>
		</Alert>
	);
}
