import { List } from "@mui/material";
import CommentCard from "components/Cards/CommentCard";

export default function CommentsList() {
	return (
		<List dense>
			<CommentCard />
			<CommentCard />
			<CommentCard />
			<CommentCard />
			<CommentCard />
			<CommentCard />
		</List>
	);
}
