import { Box, Divider, Stack, Typography } from "@mui/material";

export default function WelcomeDes() {
	return (
		<Box
			sx={{
				bgcolor: "background.default",
				p: 3,
				borderRadius: (t) => t.spacing(1),
			}}
		>
			<Stack dir="ltr" spacing={2}>
				<Box component="img" src="/assets/logo.png" width={48} />
				<Typography
					fontSize={"1.5em"}
					component={"h1"}
					fontWeight={"bold"}
					fontFamily={"Quicksand"}
					lineHeight={1}
				>
					NEXT MENU
				</Typography>
				<Typography
					fontSize={".9em"}
					component={"span"}
					fontWeight={"light"}
					fontFamily={"Quicksand"}
					lineHeight={1}
				>
					Manage your services or products at the moment.
				</Typography>
				<Divider />
				<Typography
					fontSize={".8em"}
					component={"span"}
					textAlign={"justify"}
					lineHeight={1.5}
					dir="rtl"
				>
					تهیه و بروزرسانی منو متناسب با کسب و کار خود ، به کمک طرح های پیشفرض
					یا به صورت سفارشی ، شانس بیشتر در جستجو های اینترنتی با استفاده از
					پکیج های سئو و ارتباط پویاتر با مشتریان بدون خجالت.
				</Typography>
				<Typography
					fontSize={".8em"}
					component={"strong"}
					lineHeight={1.5}
					dir="rtl"
					fontWeight={"bold"}
					textAlign="justify"
				>
					ما فکر همه‌جا رو کردیم ...
					<br />
					البته یادتون نره که گوش ما پر از جای خالی برای شنیدن ایده های شما ،
					برای ارتقای این برنامست ، که متعلق به خودتونه {`:)`}
				</Typography>
			</Stack>
		</Box>
	);
}
