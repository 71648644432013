import { Button } from "@mui/material";
import React, { useCallback, useId, useState, useTransition } from "react";
import { createPortal } from "react-dom";
import AddCustomMenuForm from "./AddCustomMenuForm";

export default function AddCustomMenu() {

    const [open, setOpen] = useState(false);
    const [isPending, startTransition] = useTransition();

    const handleOpen = useCallback(() => {
        startTransition(() => setOpen(true));
    }, []);

    const handleClose = useCallback(() => {
        startTransition(() => setOpen(false));
    }, []);

    return (
        <React.Fragment>
            <Button onClick={handleOpen} fullWidth color="inherit">ثبت گزینه دلخواه</Button>
            {open && <AddCustomMenuForm handleClose={handleClose} />}
        </React.Fragment>
    )
}