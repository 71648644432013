import PageLayout from "components/PageLayout";
import UpdateUserProfileForm from "./UpdateUserProfileForm";

export default function UpdateUserProfile() {
	return (
		<PageLayout
			topAppBar={{
				title: "بروزرسانی پروفایل",
				arrowBack: true,
			}}
			backPath="/profile"
		>
			<UpdateUserProfileForm />
		</PageLayout>
	);
}
