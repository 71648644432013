import { Icon } from "@iconify/react";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { useDeleteBusinessMenuItemMutation } from "features/entities/menuItems";
import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

export type BmiActionsProps = { id: number | string; name: string };

export default function BmiActions({ id, name }: BmiActionsProps) {
	const [remove, removeApi] = useDeleteBusinessMenuItemMutation();

	const { menu_id } = useParams();
	const navigate = useNavigate();

	const handleEdit = useCallback(
		(id: BmiActionsProps["id"]) => {
			navigate(`/dashboard/menus/${menu_id}/items/${id}`);
		},
		[menu_id]
	);

	const handleDiscount = useCallback(() => {
		window.alert("این امکان درحال راه اندازی می‌باشد!");
	}, []);

	const handleRemove = useCallback(
		async (id: BmiActionsProps["id"], name: BmiActionsProps["name"]) => {
			if (window.confirm(`میخواهید ${name} را حذف کنید؟`)) {
				try {
					await remove({ menu_id, id })
				} catch (err) {
					console.error(err);
				}
			}
		},
		[menu_id, id]
	);

	return (
		<Stack direction="row" spacing={2} alignItems={"center"}>
			<Tooltip title="ویرایش">
				<IconButton color="info" onClick={() => handleEdit(id)} size="small">
					<Icon icon="material-symbols:edit" />
				</IconButton>
			</Tooltip>
			<Tooltip title="حذف">
				<IconButton color="error" onClick={() => handleRemove(id, name)} size="small">
					<Icon icon="fluent:delete-16-regular" />
				</IconButton>
			</Tooltip>
			<Tooltip title="تخفیف">
				<IconButton color="warning" onClick={handleDiscount} size="small">
					<Icon icon="tabler:discount-2" />
				</IconButton>
			</Tooltip>
		</Stack>
	);
}
