import { Icon } from "@iconify/react";
import {
	Avatar,
	Divider,
	IconButton,
	Stack,
	Tooltip
} from "@mui/material";
import { useFormikContext } from "formik";
import { memo, useMemo, useRef } from "react";
import { Media } from "types/media";

export type ImageFieldPreviewProps = {
	title?: string;
	formikField: string;
	size?: number;
};

function ImageFieldPreview({
	title,
	formikField,
	size = 96,
}: ImageFieldPreviewProps) {
	const { values, setFieldValue } = useFormikContext<{
		[key: typeof formikField]: Media | File | null;
	}>();
	const fileInput = useRef<HTMLInputElement | null>(null);

	const imageUrl = useMemo(() => {
		const data = values[formikField];
		if (data instanceof File) {
			return URL.createObjectURL(data);
		}

		if (data?.filename) {
			return data.url;
		}

		return undefined;
	}, [values[formikField]]);

	return (
		<Divider>
			<Stack
				direction="row"
				spacing={1}
				alignItems={"center"}
				justifyContent={"center"}
			>
				<Tooltip title="انتخاب" placement="top">
					<IconButton
						disabled={Boolean(imageUrl)}
						onClick={() => {
							if (fileInput?.current) {
								fileInput.current.focus();
								fileInput.current.click();
							}
						}}
					>
						<Icon icon="material-symbols:image-search" />
					</IconButton>
				</Tooltip>
				<Avatar
					className="next-shadow"
					alt={title}
					variant="circular"
					src={imageUrl}
					sx={{ width: size, height: size }}
					imgProps={{ crossOrigin: "use-credentials" }}
				>
					{title}
				</Avatar>
				<Tooltip title="حذف انتخاب" placement="top">
					<IconButton
						color="error"
						disabled={!imageUrl}
						onClick={() => {
							if (imageUrl) {
								setFieldValue(formikField, null);
							}
						}}
					>
						<Icon icon="basil:cancel-outline" />
					</IconButton>
				</Tooltip>
				<input
					name={formikField}
					type="file"
					hidden
					ref={fileInput}
					onChange={(e) => {
						if (e.currentTarget.files?.length === 1) {
							setFieldValue(formikField, e.currentTarget.files.item(0));
						}
					}}
				/>
			</Stack>
		</Divider>
	);
}

export default memo(ImageFieldPreview);
