import { FormGroup } from "@mui/material";
import TemplateSelect from "./TemplateSelect";
import IconPackageSelect from "./IconPackageSelect";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import ColorPaletteToggler from "./ColorPaletteToggler";

export default function FormInputs() {
	return (
		<Grid2 container spacing={2}>
			<Grid2 xs={12} md={6}>
				<TemplateSelect />
			</Grid2>
			<Grid2 xs={12} md={6}>
				<IconPackageSelect />
			</Grid2>
			<Grid2 xs={12}>
				<ColorPaletteToggler />
			</Grid2>
		</Grid2>
	);
}
