import { LoadingButton } from "@mui/lab";
import { FormGroup } from "@mui/material";
import { useSFD } from "components/SwipeableFormDrawer/context";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import useApi from "hooks/useApi";
import { useEffect } from "react";

export default function UpdateUserPasswordForm() {

    const api = useApi();
    const { handleCloseDrawer,handleMountingDrawer } = useSFD();

    useEffect(() => {
        handleMountingDrawer();
    }, []);

	return (
		<Formik
			initialValues={{
				password: "",
				repeat_password: "",
			}}
            onSubmit={async (data, { setSubmitting }) => {
                try {
                    const response = await api(`/profile/password/set`, {
                        body: JSON.stringify(data),
                        method: "post"
                    });
					if(response.status === 200) {
						return handleCloseDrawer();
					}
                } finally {
                    setSubmitting(false);
                }
            }}
		>
            {({ isSubmitting }) => (
                <Form>
				<FormGroup>
					<Field
						component={TextField}
						type="password"
						name="password"
						label="رمز عبور"
						dir="ltr"
					/>
					<Field
						component={TextField}
						type="password"
						name="repeat_password"
						label="تکرار رمز"
						dir="ltr"
					/>
					<LoadingButton loading={isSubmitting} type="submit" variant="contained" color="secondary">
						بروزرسانی
					</LoadingButton>
				</FormGroup>
			</Form>
            )}
		</Formik>
	);
}
