import { SwipeableDrawer } from "@mui/material";
import {
	ReactNode
} from "react";
import SwipeableFormDrawerProvider from "./SwipeableFormDrawerProvider";
import { Consumer } from "./context";

export type SwipeableFormDrawerProps = {
	children: ReactNode;
};

const SwipeableFormDrawer = ({ children }: SwipeableFormDrawerProps) => {

	return (
		<SwipeableFormDrawerProvider>
			<Consumer>
				{({ mounted, handleCloseDrawer }) => (
					<SwipeableDrawer
						onOpen={() => undefined}
						open={mounted}
						onClose={() => handleCloseDrawer()}
						anchor="bottom"
						transitionDuration={{
							enter: 4e2,
							exit: 300
						}}
						PaperProps={{
							sx: {
								p: 2,
								borderRadius: (theme) => theme.spacing(2, 2, 0, 0),
								maxHeight: "80vh",
								maxWidth: theme => theme.breakpoints.values.sm,
								mx: "auto",
								"&::after": {
									content: "'	'",
									width: theme => theme.spacing(4),
									height: theme => theme.spacing(.8),
									position: "absolute",
									top: theme => theme.spacing(1),
									left: "50%",
									transform: "translateX(-50%)",
									backgroundColor: theme => theme.palette.grey[300],
									borderRadius: theme => theme.spacing(.3),
									transition: theme => theme.transitions.create("all", {
										easing: theme.transitions.easing.easeIn,
										duration: theme.transitions.duration.enteringScreen
									})
								},
								"&:hover": {
									"&::after": {
										backgroundColor: theme => theme.palette.grey[400]
									}
								}
							},
						}}
					>
						{children}
					</SwipeableDrawer>
				)}
			</Consumer>
		</SwipeableFormDrawerProvider>
	);
};

export default SwipeableFormDrawer;
