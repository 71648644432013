import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import { Card, CardActions, CardContent, FormGroup } from "@mui/material";
import ChooseAvatarInput from "components/ChooseAvatarInput";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { TextField } from "formik-mui";
import useApi from "hooks/useApi";
import { enqueueSnackbar } from "notistack";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { updateProfile } from "reducers/account";
import { useAppDispatch, useAppSelector } from "redux/store";
import type { Media } from "types/media";
import * as Yup from "yup";

type FormProps = {
	first_name: string;
	last_name: string;
	email: string;
	nid: string;
	avatar: Media | File | null;
};

export default function UpdateUserProfileForm() {
	const account = useAppSelector((state) => state.account);

	const api = useApi();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const handleSubmit = useCallback(
		async (data: FormProps, { setSubmitting }: FormikHelpers<FormProps>) => {
			try {
				const fd = new FormData();
				fd.append("first_name", data.first_name);
				fd.append("last_name", data.last_name);
				fd.append("nid", data.nid);
				fd.append("email", data.email);
				if (data.avatar instanceof File) {
					fd.append("avatar", data.avatar, data.avatar.name);
				}

				const response = await api(`/profile`, {
					method: "PUT",
					body: fd,
				});

				if (response?.data?.profile) {
					dispatch(updateProfile(response.data.profile));
				}

				return navigate("/profile", { replace: true });
			} catch (err: any) {
				enqueueSnackbar(err?.response?.data?.message);
			} finally {
				setSubmitting(false);
			}
		},
		[dispatch]
	);

	return (
		<Card>
			<Formik
				initialValues={account?.profile as FormProps}
				onSubmit={handleSubmit}
				validationSchema={Yup.object()
					.shape({
						first_name: Yup.string().required(),
						last_name: Yup.string().required(),
						nid: Yup.string()
							.matches(/[0-9]/, { message: "فقط مقدار عددی" })
							.length(10, "طول ارقام صحیح نمی باشد")
							.required("وارد کردن کد ملی الزامیست!"),
					})
					.required()}
			>
				{({ isSubmitting }) => (
					<Form>
						<CardContent>
							<FormGroup>
								<ChooseAvatarInput />
								<Field
									label="نام"
									component={TextField}
									name="first_name"
									required
								/>
								<Field
									label="نام خانوادگی"
									component={TextField}
									name="last_name"
									required
								/>
								<Field
									label="شماره ملی"
									component={TextField}
									name="nid"
									type="tel"
									inputMode={"numeric"}
									pattern={/{[0-9]d}10/}
									required
									dir="ltr"
								/>
								<Field
									dir="ltr"
									label="ایمیل"
									component={TextField}
									name="email"
									type="email"
									required
								/>
								{/* <Field
								label="تاریخ تولد"
								component={TextField}
								name="birth_date"
								type="date"
								required
							/> */}
							</FormGroup>
						</CardContent>
						<CardActions>
							<LoadingButton
								variant="contained"
								startIcon={<Icon icon="radix-icons:update" />}
								loading={isSubmitting}
								type="submit"
							>
								بروزرسانی
							</LoadingButton>
						</CardActions>
					</Form>
				)}
			</Formik>
		</Card>
	);
}
