import { ButtonGroup, Chip, Grid, IconButton, List, ListItem, Stack, Tooltip } from "@mui/material";
import BusinessMenuItem from "components/BusinessMenuItem";
import AutoSwipeableLayout from "components/layouts/AutoSwipeableLayout";
import {
	useDeleteBusinessMenuMutation,
	useGetBusinessMenuQuery,
} from "features/entities/menus";
import { Fragment, useCallback } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import AddBusinessMenuItemButton from "./AddBusinessMenuItemButton";
import ZeroMenuItems from "./ZeroMenuItems";
import { Icon } from "@iconify/react";
import PageLayout from "components/PageLayout";

const BusinessMenuActions = ({ id }: { id: number | string; }) => {
	const [removeMenu, api] = useDeleteBusinessMenuMutation();
	const navigate = useNavigate();

	const handleRemove = useCallback(async (id: any) => {
		if (window.confirm("آیا میخواهید منو را حذف کنید؟")) {
			removeMenu(id).unwrap().then(() => navigate("/dashboard/menus"));
		}
	}, [removeMenu]);
	return (
		<Stack
			direction="row"
			spacing={1}
			justifyContent={"flex-end"}
			alignItems={"center"}
		>
			<Chip disabled onClick={() => undefined} icon={<Icon width={24} icon="material-symbols:merge-type-rounded" />} label="تجمیع" color="info" />
			<Chip onClick={() => handleRemove(id)} icon={<Icon width={24} icon="material-symbols:cancel-outline-rounded" />} label="حذف منو" color="error" />
		</Stack>
	);
};

const BusinessMenuItems = () => {
	const { menu_id } = useParams();
	const { data, isLoading } = useGetBusinessMenuQuery({
		menu_id: menu_id as string,
	});

	return (
		<PageLayout
			topAppBar={{
				title: data?.reference?.name_persian,
				secondaryActions: <AddBusinessMenuItemButton />,
				arrowBack: true,
			}}
			bottomAppBar={{
				secondaryActions: <BusinessMenuActions id={menu_id as unknown as string} />,
			}}
			backPath="/dashboard/menus"
		>
			{data?.items?.length === 0 && <ZeroMenuItems />}
			<Grid container alignItems="stretch" component={List}>
				{data?.items?.map((item: any, index: number) => (
					<Grid item key={`business-menu-item-${index}`} xs={12} md={4} lg={3} component={ListItem}>
						<BusinessMenuItem {...item} />
					</Grid>
				))}
			</Grid>
			<Outlet />
		</PageLayout>
	);
};

export default BusinessMenuItems;
