import React, {useState} from "react";
import SwipeableViews from "react-swipeable-views";
import {Box, Button, Container, Stack, Typography} from "@mui/material";

// images
import slide1 from "./images/slide1.svg";
import slide2 from "./images/slide2.svg";
import slide3 from "./images/slide3.svg";
import {NavLink} from "react-router-dom";

const contents = [
    {
        body: "ماتریکس منو سامانه آنلاین برای طراحی و ساخت منوی دیجیتالی رستوران شما جهت نمایش در گوشی های همراه مشتریان می باشد. شما می توانید تعداد غذا و قیمت را روزانه با توجه به نوسانات بالای قیمت مواد اولیه تغییر دهید .",
        image: slide1
    },
    {
        body: "با ورود به سامانه و ثبت نام از طریق شماره همراه، میتوانید حساب کاربری مدیریتی خود را داشته و ساخت ، دیزاین و تنظیمات منو دیجیتال خود را بر عهده داشته باشید.",
        image: slide2
    },
    {
        body: "پس از ساخت حساب کاربری، تنظیماتی مانند تعداد ، پرس و سایز آیتم مورد نظر نیز امکان پذیر خواهد بود .\n" +
            " همچنین میتوانید منو ها و آیتم های خود را دسته بندی کنید.",
        image: slide3
    }
]

function WelcomeSection({body, image, showLogin}: { body: any; image?: string; showLogin: boolean; }) {
    return (
        <Stack direction={"column"} gap={1} alignItems={"flex-start"} justifyContent={"center"} height={"100vh"}>
            {image && (
                <Box component={"img"} sx={{
                    aspectRatio: "1/1",
                    width: "100%",
                }} src={image} alt={""}/>
            )}
            <Typography px={2} lineHeight={2} dir={"rtl"} paragraph fontSize={"15px"} fontWeight={500}
                        textAlign={"justify"}>{body}</Typography>
            {showLogin && (<NavLink to={"/auth/login"} style={{margin: "0 auto"}}>
                <Button size={"large"}>ورود به برنامه</Button>
            </NavLink>)}
        </Stack>
    )
}

function Dots({index}: { index: number; }) {
    return (
        <Box sx={{
            position: "fixed",
            left: "50%",
            transform: "translateX(-50%)",
            bottom: "10%",
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
            gap: theme => theme.spacing(1)
        }}>
            {contents.map((content, i) => (
                <Box key={`sv-dot-${i}`} sx={{
                    borderRadius: "50%",
                    width: 15,
                    height: 15,
                    bgcolor: "primary.light",
                    ...(index === i && {
                        bgcolor: "secondary.main"
                    })
                }}/>
            ))}
        </Box>
    )
}

export default function Welcome() {
    const [index, setIndex] = useState(0);
    return (
        <Container maxWidth={"sm"} sx={{position: "relative"}}>
            <SwipeableViews animateHeight
                            animateTransitions
                            enableMouseEvents
                            threshold={10}
                            index={index} onChangeIndex={(index) => setIndex(index)}>
                {contents.map((content, index) => (<WelcomeSection showLogin={index === (contents.length-1)} {...content} key={`wc-content-${index}`}/>))}
            </SwipeableViews>
            <Dots index={index}/>
        </Container>

    )
}