import { Container, Slide } from "@mui/material";
import { ReactNode, useEffect, useState, useTransition } from "react";

export default function Body({ children }: { children: ReactNode }) {
	const [mounted, setMounted] = useState(false);
	const [isLoading, startTransition] = useTransition();

	useEffect(() => {
		startTransition(() => {
			setMounted(true);
		});

		return () => setMounted(false);
	}, []);

	return (
		<Slide in={mounted} direction="up" timeout={700}>
			<Container sx={{ flex: 1, mb: 2 }} maxWidth="lg" component="main">
				{children}
			</Container>
		</Slide>
	);
}
