import { createSlice } from "@reduxjs/toolkit";

type InitialState = {
	mobile: string | null;
	user_summary: { first_name: string; last_name: string } | null;
	token: string | null;
};

const initialState: InitialState = {
	mobile: null,
	user_summary: null,
	token: null,
};

const auth = createSlice({
	name: "@auth",
	initialState,
	reducers: {
		storeLoginData: (state, action) => {
			state.mobile = action.payload.mobile;
			state.user_summary = action.payload.user_summary;
		},
		clearLoginData: (state) => ({ ...state, mobile: null, token: null }),
	},
});

export const { storeLoginData, clearLoginData } = auth.actions;

export default auth.reducer;
