import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import {
	Button,
	ButtonGroup,
	DialogActions,
	DialogContent, FormGroup
} from "@mui/material";
import { useSFD } from "components/SwipeableFormDrawer/context";
import { useAddBusinessMenuItemMutation } from "features/entities/menuItems";
import { Form, Formik } from "formik";
import useFormHelper from "hooks/useFormHelper";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import FormFields from "../FormFields";

export type AddMenuItemValues = {
	name: string;
	caption: string;
	price: string;
	thumbnail: File | null;
};

const AddBusinessMenuItemForm = () => {
	const { handleCloseDrawer, handleMountingDrawer } = useSFD();
	const menu_id = useParams().menu_id as unknown as number;
	const [addMenuItem, api] = useAddBusinessMenuItemMutation();
	const { serialize } = useFormHelper();

	useEffect(() => {
		handleMountingDrawer();
	}, []);

	return (
		<Formik
			initialValues={
				{
					name: "",
					caption: "",
					price: "",
				} as unknown as AddMenuItemValues
			}
			onSubmit={async (data, { setSubmitting }) => {
				try {
					const formData = serialize(data);
					await addMenuItem({ body: formData, menu_id }).unwrap();
					return handleCloseDrawer(`/dashboard/menus/${menu_id}`);
				} finally {
					setSubmitting(false);
				}
			}}
		>
			<Form>
				<DialogContent>
					<FormGroup>
						<FormFields />
					</FormGroup>
				</DialogContent>
				<DialogActions>
					<ButtonGroup fullWidth disableElevation size="small">
						<LoadingButton
							type="submit"
							variant="contained"
							size="small"
							color="primary"
							disableElevation
						>
							ثبت
						</LoadingButton>
						<Button
							onClick={() => handleCloseDrawer()}
							color="error"
							variant="contained"
						>
							<Icon
								icon="material-symbols:close-rounded"
								alignmentBaseline="middle"
								width={18}
								height={18}
							/>
						</Button>
					</ButtonGroup>
				</DialogActions>
			</Form>
		</Formik>
	);
};

export default AddBusinessMenuItemForm;
