import SwipeableFormDrawer from "components/SwipeableFormDrawer";
import EditBusinessMenuItemForm from "./EditBusinessMenuItemForm";

const EditBusinessMenuItem = () => {

	return (
		<SwipeableFormDrawer>
			<EditBusinessMenuItemForm />
		</SwipeableFormDrawer>
	);
};

export default EditBusinessMenuItem;
