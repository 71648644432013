import { Icon } from "@iconify/react";
import {
	List,
	ListItem,
	ListItemButton,
	ListItemTextProps,
	ListItemText as MuiListItemText,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useCallback } from "react";
import { NavLink } from "react-router-dom";
import { clearCredentials } from "reducers/account";
import { useAppDispatch, useAppSelector } from "redux/store";

const ListItemText = (props: ListItemTextProps) => (
	<MuiListItemText
		primaryTypographyProps={{
			fontSize: ".8em",
			color: "GrayText",
			fontWeight: "light",
		}}
		secondaryTypographyProps={{
			fontSize: "1em",
			color: "#222",
			fontWeight: "medium",
		}}
		sx={{ ml: 2 }}
		{...props}
	/>
);

export default function UserDetails() {
	const account = useAppSelector((state) => state.account);
	const ws = useAppSelector((state) => state.workspace);

	const dispatch = useAppDispatch();

	const handleUserLogout = useCallback(() => {
		if (window.confirm("میخواهید از اکانت خود خارج شوید؟")) {
			enqueueSnackbar("به امید دیدار مجدد :)");
			dispatch(clearCredentials());
		}
	}, [dispatch]);

	return (
		<List sx={{ mt: 3 }}>
			<ListItem dense divider>
				<Icon icon="teenyicons:face-id-outline" />
				<ListItemText primary="شماره ملی" secondary={account?.profile?.nid} />
			</ListItem>
			<ListItem dense divider>
				<Icon icon="fluent-mdl2:birthday-cake" />
				<ListItemText
					primary="تاریخ تولد"
					secondary={account?.profile?.birth_date?.toLocaleString() || "-"}
				/>
			</ListItem>
			<ListItem dense divider>
				<Icon icon="material-symbols:business-center-rounded" />
				<ListItemText
					primary="تعداد کسب و کار فعال"
					secondary={0 || ws.businesses?.length}
				/>
			</ListItem>
			<ListItem dense divider>
				<Icon icon="fluent-mdl2:pen-workspace" />
				<ListItemText
					primary="کسب و کار درحال مدیریت"
					secondary={ws.business?.brand || "هیچ کدام"}
				/>
			</ListItem>
			<ListItemButton divider disabled>
				<Icon icon="fluent:cloud-backup-16-regular" />
				<ListItemText primary="تهیه نسخه پشتیبان" />
				<Icon icon="material-symbols:chevron-left-rounded" />
			</ListItemButton>
			<ListItemButton divider disabled>
				<Icon icon="mdi:cash-100" />
				<ListItemText primary="شارژ حساب کاربری" />
				<Icon icon="material-symbols:chevron-left-rounded" />
			</ListItemButton>
			<NavLink to="/profile/report">
				<ListItemButton sx={{ color: "red" }} divider>
					<Icon icon="material-symbols:bug-report-outline-rounded" />
					<ListItemText primary="گزارش مشکل فنی" />
					<Icon icon="material-symbols:chevron-left-rounded" />
				</ListItemButton>
			</NavLink>
			<NavLink to="/profile/password/update">
				<ListItemButton divider>
					<Icon icon="teenyicons:password-outline" />
					<ListItemText primary="رمز عبور ثابت" />
					<Icon icon="material-symbols:chevron-left-rounded" />
				</ListItemButton>
			</NavLink>
			<ListItemButton sx={{ color: "darkorange" }} onClick={handleUserLogout}>
				<Icon icon="ic:round-exit-to-app" />
				<ListItemText primary="خروج از اکانت" />
				<Icon icon="material-symbols:chevron-left-rounded" />
			</ListItemButton>
		</List>
	);
}
