import { CircularProgress, Modal, Paper, Typography, styled } from "@mui/material";

const ModalRoot = styled(Paper)(({ theme }) => ({
	position: "fixed",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	padding: theme.spacing(2),
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	gap: theme.spacing(2),
    boxShadow: "none"
}));

export default function ApplicationLoading() {
	return <Modal open>
        <ModalRoot>
            <CircularProgress />
            <Typography component={"span"} fontSize="1rem" fontWeight={"regular"}>آماده سازی اپلیکیشن</Typography>
        </ModalRoot>
    </Modal>;
}
