import {
	CardActions,
	CardContent,
	CardHeader,
	CardMedia,
	Divider,
	Card as MuiCard,
	Typography,
	cardActionsClasses,
	cardContentClasses,
	cardHeaderClasses,
	cardMediaClasses,
	styled
} from "@mui/material";
import type { BusinessMenuItem as BMI } from "types/BusinessMenuItem";
import BmiActions from "./BmiActions";
import ToggleVisibility from "./ToggleVisibility";

const Card = styled(MuiCard)(({ theme }) => ({
	boxShadow: "0 3px 6px rgba(0,0,0,.092)",
	borderRadius: theme.spacing(1),
	marginInline: "auto",
	height: "100%",
	width: "100%",
	[`& .${cardHeaderClasses.title}`]: {
		fontWeight: 500,
		fontSize: "1.2em",
	},
	[`& .${cardHeaderClasses.subheader}`]: {
		fontSize: ".7em",
		color: "GrayText",
		fontWeight: 300,
	},
	[`& .${cardMediaClasses.root}`]: {
		objectFit: "contain",
		objectPosition: "center center",
		backgroundColor: "#f9f9f9"
	},
	[`& .${cardActionsClasses.root}`]: {
		justifyContent: "flex-start"
	},
	[`& .${cardContentClasses.root}`]: {
		fontSize: ".8em"
	}
}));

export default function BusinessMenuItem(props: BMI) {
	const {
		name,
		caption,
		price,
		updatedAt,
		id,
		thumbnail,
		menu_id,
		visibility,
	} = props;

	return (
		<Card className="next-shadow">
			<CardHeader
				title={name}
				subheader={`بروزرسانی: ${new Date(updatedAt).toLocaleDateString()}`}
				action={
					<ToggleVisibility id={id} menu_id={menu_id} visibility={visibility} />
				}
			/>
			<CardMedia
				crossOrigin="use-credentials"
				width={164}
				height={164}
				image={thumbnail?.url}
				component="img"
				alt={name}
			/>
			<Divider/>
			<CardActions
				sx={{
					px: 2,
					gridArea: "footer",
				}}
			>
				<BmiActions id={id} name={name} />
			</CardActions>
			<Divider />
			<CardContent>
				<Typography component="div" fontSize="inherit" color="GrayText">
					{caption}
				</Typography>
				<Typography
					component="strong"
					fontWeight="bold"
					color="#444"
					fontSize="inherit"
				>
					قیمت مصرف کننده: {price}
				</Typography>
			</CardContent>
		</Card>
	);
}
