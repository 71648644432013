import { Icon } from "@iconify/react";
import { Alert, AlertTitle, Box, Button, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";

export default function NoBusiness() {
	return (
		<Box
			sx={{
				position: "fixed",
				left: "50%",
				top: "50%",
				transform: "translate(-50%, -50%)",
				display: "grid",
				placeItems: "center",
				placeContent: "center",
			}}
		>
			<Alert severity="error" variant="filled">
				<AlertTitle>خطا!</AlertTitle>
				<Typography component={"span"} fontSize=".9em" display={"block"}>
					برای دسترسی به داشبورد نیازمند حداقل یک کسب و کار هستید.
				</Typography>
				<NavLink to={"/submit-business"} replace>
					<Button
						startIcon={<Icon icon="material-symbols:fiber-new" />}
						sx={{ mt: 2 }}
						variant="contained"
						color="secondary"
                        size="medium"
					>
						ایجاد کسب و کار
					</Button>
				</NavLink>
			</Alert>
		</Box>
	);
}
