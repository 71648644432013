import { Button, FormControl } from "@mui/material";
import FormikImagePreview from "components/FormikImagePreview";
import { Field, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import { Fragment } from "react";
import PriceField from "./PriceField";

export default function FormFields() {
    const { setFieldValue } = useFormikContext<any>();

	return (
		<Fragment>
			<Field
				component={TextField}
				name="name"
				label="نام آیتم"
				dir="rtl"
				required
			/>
			<Field
				component={TextField}
				name="caption"
				label="توضیحات آیتم"
				dir="rtl"
				helperText={
					"این فیلد میتواند محتوی مواد تشکیل دهنده ، انواع طبخ ، طریقه سرو و ... باشد"
				}
				multiline
				rows={3}
			/>
			<PriceField />
			<FormControl>
				<Button component="label" variant="outlined">
					<input
						multiple={false}
						accept="image/*"
						type="file"
						hidden
						name="thumbnail"
						onChange={(event) => {
							if (event.target.files && Boolean(event.target.files.item(0))) {
								setFieldValue("thumbnail", event.target.files?.item(0));
							}
						}}
					/>
					انتخاب تصویر شاخص
				</Button>
			</FormControl>
			<FormControl>
				<FormikImagePreview fieldname="thumbnail" />
			</FormControl>
		</Fragment>
	);
}
