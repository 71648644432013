import { FormControl, InputLabel, ListItemText, MenuItem, Select } from "@mui/material";
import { useGetMenuTitlesQuery } from "features/entities/helpers";
import { useFormikContext } from "formik";
import { useId } from "react";
import { MenuReference } from "types/MenuReference";

const label = "عنوان منو";

export default function MenuReferenceSelectBox() {


    const { data, isLoading } = useGetMenuTitlesQuery(null);

    const labelId = useId();

    const { getFieldProps, touched, errors, isSubmitting } = useFormikContext();

    return (
        <FormControl>
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select labelId={labelId} label={label} {...getFieldProps("reference_id")} disabled={isSubmitting}>
                {data?.map((menu: MenuReference, index: number) => (
                    <MenuItem value={menu.id} key={`menu-reference-${index}`} divider dense>
                        <ListItemText primary={menu.name_persian} secondary={menu.name_english} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}