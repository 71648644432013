import {
	AppBar as MuiAppBar,
	Box,
	styled,
	Toolbar,
	toolbarClasses,
	Slide,
	useScrollTrigger,
} from "@mui/material";
import { ReactNode, useCallback } from "react";

const AppBar = styled(MuiAppBar)(({ theme }) => ({
	width: `calc(100% - ${theme.spacing(4)})`,
	inset: theme.spacing(2),
	top: "auto",
	backgroundImage: `linear-gradient(-15deg, ${theme.palette.secondary.main}, ${theme.palette.secondary.light})`,
	color: "#fff",
	borderRadius: theme.spacing(2),
	[`& .${toolbarClasses.root}`]: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		color: "inherit",
	},
}));

export type BottomAppBarProps = {
	primaryActions?: ReactNode;
	secondaryActions?: ReactNode;
	children?: ReactNode;
};

function BottomAppBar(props: BottomAppBarProps) {
	const render = useCallback(() => {
		if (props.children) {
			return props.children;
		}

		return (
			<Toolbar>
				{props.primaryActions}
				<Box flex={1} />
				{props.secondaryActions}
			</Toolbar>
		);
	}, []);

	const trigger = useScrollTrigger({
		threshold: 10,
		disableHysteresis: false,
	});

	return (
		<>
			<Toolbar sx={{ mt: 2 }} />
			<Slide direction="up" in={!trigger}>
				<AppBar className="next-shadow" position="fixed">
					{render()}
				</AppBar>
			</Slide>
		</>
	);
}

export default BottomAppBar;
