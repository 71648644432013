import { Alert, Button, ListItem, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function NoBusinessFound() {
	const navigate = useNavigate();
	return (
		<ListItem>
			<Alert sx={{ width: "100%" }} severity="warning" variant="filled">
				<Typography gutterBottom fontSize="small">
					موردی برای نمایش وجود ندارد!
				</Typography>
				<Button onClick={() => navigate("/submit-business", { replace: true })}>
					ایجاد اولین کسب و کار
				</Button>
			</Alert>
		</ListItem>
	);
}
