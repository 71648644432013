import { Icon } from "@iconify/react";
import {
	AppBar,
	Box, Chip,
	CircularProgress,
	Divider,
	Drawer,
	IconButton,
	List, styled,
	Toolbar,
	Tooltip,
	Typography
} from "@mui/material";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { toggleSelectOpen } from "reducers/workspace";
import { useAppDispatch, useAppSelector } from "redux/store";
import UserBusiness from "types/business";
import BusinessCard from "./BusinessCard";
import NoBusinessFound from "./NoBusinessFound";

const Root = styled(Box)(({ theme }) => ({
	// backgroundColor: "#fff",
	// borderBottom: `1px solid ${theme.palette.divider}`,
	width: "100%",
	display: "grid",
	placeItems: "center",
	alignItems: "center",
	paddingBlock: theme.spacing(1),
	// marginBottom: theme.spacing(2),
}));

const BusinessSwitcher = () => {
	const open = useAppSelector((state) => state.workspace.select_open);
	const dispatch = useAppDispatch();

	const { business, businesses, loading } = useAppSelector(
		(state) => state.workspace
	);

	const navigate = useNavigate();

	const toggleSwitch = useCallback((state: boolean) => dispatch(toggleSelectOpen(state)), [dispatch]);

	return (
		<Root>
			<Tooltip title="انتخاب کسب و کار">
				<Chip
					label={business?.brand}
					variant="outlined"
					icon={
						loading ? (
							<CircularProgress size={16} />
						) : (
							<Icon icon="ic:round-keyboard-arrow-down" />
						)
					}
					onClick={() => toggleSwitch(true)}
					clickable
					sx={{
						backgroundColor: "#fff",
					}}
					size="small"
				/>
			</Tooltip>
			<Drawer
				open={open}
				onClose={() => toggleSwitch(false)}
				anchor="bottom"
				PaperProps={{
					sx: {
						maxHeight: "70vh",
						bgcolor: "background.default",
					},
				}}
			>
				<React.Fragment>
					<AppBar position="sticky" color="default">
						<Toolbar>
							<Typography fontWeight={500} fontSize="medium" flex={1}>
								انتخاب کسب و کار
							</Typography>
							<IconButton onClick={() => toggleSwitch(false)}>
								<Icon icon="material-symbols:close-rounded" />
							</IconButton>
						</Toolbar>
					</AppBar>
					<Divider sx={{ mb: 1 }} />
					<List sx={{ pt: 0 }}>
						{businesses?.map((business: UserBusiness, index: number) => (
							<BusinessCard
								key={`bussiness-li-${index}`}
								title={business.brand}
								subdomain={business?.category?.name || business.subdomain}
								logoUrl={business?.logo?.url}
								id={business.id}
							/>
						))}
						{(!businesses || businesses?.length === 0) && <NoBusinessFound />}
					</List>
				</React.Fragment>
			</Drawer>
		</Root>
	);
};

export default BusinessSwitcher;
