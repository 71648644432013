import { Icon } from "@iconify/react";
import { DialogTitle, IconButton, Stack } from "@mui/material";
import { ReactNode } from "react";

export type DialogHeaderProps = {
    children: ReactNode;
    closeHandler: () => void;
}

export default function DialogHeader({ children, closeHandler }: DialogHeaderProps) {
    return (
        <Stack direction={"row"} alignItems="center" justifyContent={"space-between"} pr={2}>
            <DialogTitle>{children}</DialogTitle>
            <IconButton onClick={closeHandler}>
                <Icon icon="ic:round-close" />
            </IconButton>
        </Stack>
    )
}