import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import {
	Button,
	ButtonGroup,
	DialogActions,
	DialogContent,
	FormGroup
} from "@mui/material";
import { useSFD } from "components/SwipeableFormDrawer/context";
import { useAddBusinessMenuMutation } from "features/entities/menus";
import { Form, Formik } from "formik";
import { useEffect } from "react";
import AddCustomMenu from "./AddCustomMenu";
import MenuReferenceSelectBox from "./MenuReferenceSelectBox";
import { useNavigate } from "react-router-dom";

export type FormValues = {
	reference_id: null | number;
};

const AddBusinessMenuForm = () => {
	const { handleCloseDrawer, handleMountingDrawer } = useSFD();

	const [addBusinessMenu, api] = useAddBusinessMenuMutation();
	const navigate = useNavigate();

	useEffect(() => {
		handleMountingDrawer();
	}, []);

	return (
		<Formik
			initialValues={
				{
					reference_id: null,
				} as FormValues
			}
			onSubmit={async (data, { setSubmitting }) => {
				try {
					await addBusinessMenu(data).unwrap();
					return navigate("/dashboard/menus");
					
				} finally {
					setSubmitting(false);
				}
			}}
		>
			<Form>
				<DialogContent>
					<FormGroup>
						<MenuReferenceSelectBox />
						<AddCustomMenu />
					</FormGroup>
				</DialogContent>
				<DialogActions>
					<ButtonGroup fullWidth disableElevation size="small">
						<LoadingButton
							type="submit"
							variant="contained"
							size="small"
							color="primary"
							disableElevation
						>
							ثبت
						</LoadingButton>
						<Button
							onClick={() => handleCloseDrawer()}
							color="error"
							variant="contained"
						>
							<Icon
								icon="material-symbols:close-rounded"
								alignmentBaseline="middle"
								width={18}
								height={18}
							/>
						</Button>
					</ButtonGroup>
				</DialogActions>
			</Form>
		</Formik>
	);
};

export default AddBusinessMenuForm;
