import { Icon } from "@iconify/react";
import ContainerIconButton from "components/Buttons/ContainerIconButton";
import { NavLink } from "react-router-dom";

export default function AddMenuButton() {
	return (
		<NavLink to={"/dashboard/menus/add"}>
			<ContainerIconButton color="primary">
				<Icon icon="material-symbols:add" />
			</ContainerIconButton>
		</NavLink>
	);
}
