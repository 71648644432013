import { Icon } from "@iconify/react";
import { IconButton } from "@mui/material";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export type ArrowBackButtonProps = {
	onBackClick?: () => void;
	path?: string;
};

export default function ArrowBackButton(props: ArrowBackButtonProps) {
	const navigate = useNavigate();

	const clickHandler = useCallback(() => {
		if (props.onBackClick) {
			return props.onBackClick();
		}
		if (props.path) {
			return navigate(props.path);
		}
		return navigate(-1);
	}, [props.onBackClick, props.path]);

	return (
		<IconButton onClick={clickHandler}>
			<Icon width={24} icon="ph:arrow-right-bold" />
		</IconButton>
	);
}
