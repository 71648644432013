import { Avatar, Box, Button, Stack, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "redux/store";

function UserSummary() {
	const account = useAppSelector((state) => state.account);

	return (
		<Stack
			direction="row"
			alignItems={"center"}
			justifyContent={"flex-start"}
			spacing={2}
		>
			<Avatar
            imgProps={{ crossOrigin: "use-credentials" }}
				src={account?.profile?.avatar?.url}
				sx={{ width: 90, height: 90 }}
				alt={account?.profile?.last_name}
			/>
			<Box>
				<Typography
					fontWeight={"bold"}
					fontSize="1em"
					component="span"
					display={"block"}
				>
					{account?.profile?.first_name} {account?.profile?.last_name}
				</Typography>
				<Typography
					fontWeight={"light"}
					fontSize=".8em"
					component="span"
					display={"block"}
				>
					{account?.mobile}
				</Typography>
				<NavLink to="/profile/edit">
					<Button color="secondary">ویرایش اطلاعات</Button>
				</NavLink>
			</Box>
		</Stack>
	);
}

export default UserSummary;