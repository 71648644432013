import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import {
	CardActions,
	CardContent,
	FormControl,
	FormGroup,
} from "@mui/material";
import ImageFieldPreview from "components/Form/ImageFieldPreview";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import useApi from "hooks/useApi";
import useFormHelper from "hooks/useFormHelper";
import { useAppDispatch } from "redux/store";
import { Settings } from ".";
import { updateBusinessInformation } from "reducers/workspace";

export default function BasicInformationForm({ data }: { data: Settings|null; }) {
	const api = useApi();
	const dispatch = useAppDispatch();
	const { serialize } = useFormHelper();

	return (
		<Formik
			initialValues={{
				brand: data?.brand || "",
				subdomain: data?.subdomain || "",
				domain: data?.domain || "",
				logo: data?.logo || null
			}}
			onSubmit={async (data, { setSubmitting }) => {
				try {
					const fd = serialize(data);

					const response = await api(`/business/settings/basic`, {
						method: "PUT",
						body: fd,
					});
					if(response.data.entry) {
						dispatch(updateBusinessInformation(response.data.entry));
					}
				} finally {
					setSubmitting(false);
				}
			}}
		>
			<Form>
				<CardContent>
					<FormGroup>
						<FormControl>
							<ImageFieldPreview formikField="logo" title="لوگو" />
						</FormControl>
						<Field
							component={TextField}
							name="brand"
							label="برند (فارسی)"
							dir="rtl"
						/>
						<Field
							component={TextField}
							name="subdomain"
							label="برند (لاتین)"
							dir="ltr"
						/>
					</FormGroup>
				</CardContent>
				<CardActions>
					<LoadingButton
						type="submit"
						variant="contained"
						startIcon={<Icon icon="radix-icons:update" />}
					>
						بروزرسانی
					</LoadingButton>
				</CardActions>
			</Form>
		</Formik>
	);
}
