import { useSnackbar } from "notistack";
import { useCallback, useEffect } from "react";
import { redirect } from "react-router-dom";
import { clearCredentials } from "reducers/account";
import { useAppDispatch, useAppSelector } from "redux/store";

const useApi = (signal?: AbortController["signal"]) => {
	const account = useAppSelector((state) => state.account);
	const controller = new AbortController();

	const dispatch = useAppDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const baseURL = process.env.REACT_APP_BASE_URL;

	const instance = useCallback(
		async (url: string, options: RequestInit = { headers: {} }) => {
			const { headers, ...rest } = options;
			try {
				const response = await fetch(baseURL + url, {
					headers: {
						Accept: "application/json",
						...(typeof options?.body === "string" && {
							"Content-Type": "application/json",
						}),
						...(account?.tokens && {
							Authorization: `Bearer ${account?.tokens?.accessToken}`,
						}),
						"Access-Control-Allow-Origin": "*",
						...headers,
					},
					signal: signal ? signal : controller.signal,
					credentials: "same-origin",
					mode: "cors",
					redirect: "follow",
					...rest,
				});

				if (response.ok) {
					const data = (await response.json()) as any;

					if (data.message) {
						enqueueSnackbar(data.message, { variant: "success" });
					}
					return {
						data,
						status: response.status,
					};
				}

				if (response.status === 401) {
					enqueueSnackbar("ابتدا وارد شوید", { variant: "info" });
					dispatch(clearCredentials());
					return Promise.reject(redirect("/auth/login"));
				}

				return Promise.reject(null);
			} catch (err: any) {
				if (err?.data?.message) {
					enqueueSnackbar(err.data.message, { variant: "error" });
				}
				return Promise.reject(err);
			}
		},
		[account?.tokens, dispatch]
	);

	useEffect(() => {
		return () => {
			if (!signal) {
				return controller.abort();
			}
		};
	}, []);

	return instance;
};

export default useApi;
