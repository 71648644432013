import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import {
	Button,
	ButtonGroup,
	DialogActions,
	DialogContent,
	FormGroup,
} from "@mui/material";
import { useSFD } from "components/SwipeableFormDrawer/context";
import { Formik, Form } from "formik";
import { useEffect } from "react";
import { useLoaderData, useParams } from "react-router-dom";
import { BusinessMenuItem } from "types/BusinessMenuItem";
import FormFields from "../FormFields";
import { useUpdateBusinessMenuitemMutation } from "features/entities/menuItems";
import useFormHelper from "hooks/useFormHelper";
import BusinessMenusSelectable from "./BusinessMenusSelectable";

export type UpdateMenuItemValues = {
	name: string;
	caption: string;
	price: string;
	thumbnail: File | null;
};

const EditBusinessMenuItemForm = () => {
	const { handleCloseDrawer, handleMountingDrawer } = useSFD();
	const { menu_id } = useParams();
	const [updateMenuItem, api] = useUpdateBusinessMenuitemMutation();
	const { serialize } = useFormHelper();

	const entry = useLoaderData() as unknown as BusinessMenuItem;

	useEffect(() => {
		handleMountingDrawer();
	}, []);

	return (
		<Formik
			enableReinitialize
			initialValues={{
				name: entry.name,
				caption: entry.caption,
				price: entry.price,
				menu_id: entry.menu_id,
			}
			}
			onSubmit={async (data, { setSubmitting }) => {
				try {
					const body = serialize(data);
					await updateMenuItem({ body, id: entry.id, menu_id }).unwrap();
					return handleCloseDrawer(`/dashboard/menus/${menu_id}`);
				} finally {
					setSubmitting(false);
				}
			}}
		>
			<Form>
				<DialogContent>
					<FormGroup>
						<BusinessMenusSelectable />
						<FormFields />
					</FormGroup>
				</DialogContent>
				<DialogActions>
					<ButtonGroup fullWidth disableElevation size="small">
						<LoadingButton
							type="submit"
							variant="contained"
							size="small"
							color="primary"
							disableElevation
						>
							ثبت
						</LoadingButton>
						<Button
							onClick={() => handleCloseDrawer()}
							color="error"
							variant="contained"
						>
							<Icon
								icon="material-symbols:close-rounded"
								alignmentBaseline="middle"
								width={18}
								height={18}
							/>
						</Button>
					</ButtonGroup>
				</DialogActions>
			</Form>
		</Formik>
	);
};

export default EditBusinessMenuItemForm;
