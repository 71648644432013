import api from "./index";

const helpersApi = api.injectEndpoints({
	overrideExisting: true,
	endpoints: (build) => ({
		getMenuTitles: build.query({
			query: () => "/helpers/menus",
			keepUnusedDataFor: 30,
			providesTags: [{ type: "HELPER_MENUS", id: "LIST" }],
		}),
		submitMenuTitle: build.mutation({
			query: (body) => ({
				url: "/helpers/menus/submit",
				body,
				method: "POST",
			}),
			invalidatesTags: [{ type: "HELPER_MENUS", id: "LIST" }],
		}),
		getTemplateOptions: build.query({
			query: () => "/helpers/templateOptions",
			keepUnusedDataFor: 120,
			providesTags: [{ type: "HELPER_TEMPLATES", id: "LIST" }],
		}),
		getIconPackageOptions: build.query({
			query: () => "/helpers/iconPackageOptions",
			keepUnusedDataFor: 120,
			providesTags: [{ type: "HELPER_ICON_PACKAGES", id: "LIST" }],
		}),
	}),
});

export const {
	useGetMenuTitlesQuery,
	useSubmitMenuTitleMutation,
	useGetTemplateOptionsQuery,
	useGetIconPackageOptionsQuery,
} = helpersApi;

export default helpersApi;
