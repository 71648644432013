import { Grid, List, ListItem } from "@mui/material";
import PageLayout from "components/PageLayout";
import { useGetBusinessMenusQuery } from "features/entities/menus";
import { Outlet } from "react-router-dom";
import AddMenuButton from "./AddMenuButton";
import ZeroMenus from "./ZeroMenus";
import BusinessMenuItem from "./components/BusinessMenuItem";

const BusinessMenus = () => {
	const { data } = useGetBusinessMenusQuery(null);

	return (
		<PageLayout
			topAppBar={{ title: "منو ها", arrowBack: true }}
			bottomAppBar={{
				primaryActions: <AddMenuButton />,
			}}
			backPath="/dashboard"
		>
			{data?.length === 0 && <ZeroMenus />}
			<Grid component={List} container alignItems="stretch">
				{data?.map((menu, index: number) => (
					<Grid
						item
						component={ListItem}
						xs={12}
						md={4}
						lg={3}
						key={`business-menu-${index}`}
					>
						<BusinessMenuItem {...menu} />
					</Grid>
				))}
			</Grid>
			<Outlet />
		</PageLayout>
	);
};

export default BusinessMenus;
