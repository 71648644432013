import SwipeableFormDrawer from "components/SwipeableFormDrawer";
import AddBusinessMenuItemForm from "./AddBusinessMenuItemForm";

const AddBusinessMenuItem = () => {

	return (
		<SwipeableFormDrawer>
			<AddBusinessMenuItemForm />
		</SwipeableFormDrawer>
	);
};

export default AddBusinessMenuItem;
