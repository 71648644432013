import { Box, Chip, Stack, Typography } from "@mui/material";
import { FieldArray } from "formik";

export default function PhonesArray() {
	return (
		<Box
			sx={{ borderRadius: (theme) => theme.spacing(0.5), p: 2, borderColor: "ButtonFace" }}
			component="fieldset"
		>
			<Typography component="legend" variant="subtitle2">تلفن ها</Typography>

			<FieldArray name="phones">
				{({ form, remove, push }) => (
					<Stack
						direction="row"
						spacing={1}
						alignItems={"center"}
						justifyContent={"flex-start"}
						flexWrap="wrap"
					>
						{form.values.phones.map((phoneNumber: string, index: number) => (
							<Chip
								label={phoneNumber}
								onDelete={() => remove(index)}
								key={`business-settings-phones-${index}`}
							/>
						))}
						<Chip
							label="افزودن شماره"
							clickable
							onClick={() => {
								const newPhoneNumber = window.prompt("شماره را وارد نمایید");
								if (newPhoneNumber && typeof newPhoneNumber === "string") {
									push(newPhoneNumber);
								}
							}}
						/>
					</Stack>
				)}
			</FieldArray>
		</Box>
	);
}
