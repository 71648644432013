import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import UserBusiness from "types/business";

interface InitialState {
	businesses: UserBusiness[] | null;
	business: UserBusiness | null;
	loading: boolean;
	select_open: boolean;
}

const initialState: InitialState = {
	businesses: null,
	business: null,
	loading: true,
	select_open: false,
};

const workspaceSlice = createSlice({
	name: "@workspace",
	initialState,
	reducers: {
		setWorkspaceState: (
			state,
			action: PayloadAction<{ key: keyof InitialState; data: any }>
		) => {
			state[action.payload.key] = action.payload.data;
		},
		clearWorkspaceState: (state) => ({
			...state,
			business: null,
			businesses: null,
		}),
		initialize: (
			state,
			action: PayloadAction<{
				lmb: (typeof initialState)["business"];
				businesses: UserBusiness[];
			}>
		) => {
			state.business = action.payload.lmb;
			state.businesses = action.payload.businesses;
			state.loading = false;
		},
		switchWorkingBusiness: (state, action: PayloadAction<UserBusiness>) => {
			state.loading = true;
			state.business = action.payload;
			state.loading = false;
		},
		updateBusinessInformation: (state, action) => {
			state.business = Object.assign({}, state.business, action.payload);
		},
		toggleSelectOpen: (state, action: PayloadAction<boolean>) => ({
			...state,
			select_open: action.payload,
		}),
	},
});

export const {
	clearWorkspaceState,
	setWorkspaceState,
	switchWorkingBusiness,
	initialize,
	toggleSelectOpen,
	updateBusinessInformation,
} = workspaceSlice.actions;

export default workspaceSlice.reducer;
